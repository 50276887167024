import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .init({
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    resources: {
      en: {
        translation: require('./locales/en.json')
      },
      ro: {
        translation: require('./locales/ro.json')
      },
      es: {
        translation: require('./locales/es.json')
      },
      it: {
        translation: require('./locales/it.json')
      },
      sv: {
        translation: require('./locales/sv.json')
      },
      el: {
        translation: require('./locales/el.json')
      },
      de: {
        translation: require('./locales/de.json')
      },
      nl: {
        translation: require('./locales/nl.json')
      },
    },
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

  export default i18next
