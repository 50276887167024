import React, { useEffect, useState } from 'react';
import { useProjectQuery} from '../features/api';
import { useCheckWp } from '../features/hooks';
import { toast } from 'react-toastify';
import FTPSSH from './FTP-SSH';
// import { Modal, CloseButton } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Loader from './Loader';
import axios from 'axios';
import CommonModal from './CommonModal';
// import crypto from 'crypto'

function AddProject() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get('type') || 'wp-connection';
  const [activeTab, setActiveTab] = useState(currentType);
  const [fileMeta, setFileMeta] = useState<any>({});
  const [showModal, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!showModal);
  // const [CreateProject] = usePutProjectMutation();
  const check = useCheckWp;
  const [wpprojectDetails, setWpProjectDetails] = useState<any>({
    projectname: '',
    description: '',
    environment: '',
    siteurl: '',
    username: '',
    app_password: '',
  });
  const [formData, setFormData] = useState({
    projectname: '',
    description: '',
    environment: '',
    composerData: '',
  });
  const [checkbox, setCheckbox] = useState(false);
  const [file, setFile] = useState('');
  const navigate = useNavigate();
  const [isFileAdded, setIsFileAdded] = useState('');
  const [isProjectAdd] = useState(false);
  const [compRequireArray, setCompRequireArray] = useState<Array<any>>([]);
  // const [value, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [showSuggestion, setShowSuggestion] = useState<boolean>(true);
  const [existingEnvList, setExistingEnvList] = useState<Array<any> | any>([]);
  const [composerFileSent, setComposerFileSent] = useState<any>({});

  const { data: projectsData, isLoading } = useProjectQuery({
    data: { 'with-plugins': true },
  });

  const projectList = !Array.isArray(projectsData) ? projectsData?.projects : [];

  const handleFileChange = (e: any) => {
    if (/\.(json)$/i.test(e.target.files[0].name) === true) {
      const fileReader = new FileReader();

      setComposerFileSent(e.target.files[0]);
      const filename = e.target.files[0].name;
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (e: any) => {
        const comp = JSON.parse(e.target.result);
        const compArray = Object.keys(comp.require).map(i => {
          const ab = i.split('/');
          if (ab.length === 1) {
            return {
              type: '',
              plugin: ab[0],
              version: comp.require[i],
            };
          } else {
            return {
              type: ab[0],
              plugin: ab[1],
              version: comp.require[i],
            };
          }
        });
        const composerMeta: any = {
          type: 'composer',
          composer: JSON.parse(e.target.result),
          file_path: '',
        };
        setCompRequireArray(compArray);
        setFileMeta(composerMeta);
        setFile(filename);
        setIsFileAdded('valid');
      };
    } else {
      setFile('');
      setIsFileAdded('invalid');
    }
  };

  //  function hashPassword(pwd: string): string {
  //    return crypto
  //      .createHash('sha256')
  //      .update(`${pwd}${process.env.SALT}`)
  //      .digest('base64');
  //  }

  const handleChange = (event: any) => {
    // const { name, value, type } = event.target;
    const { name, value } = event.target;
    // const isCheckbox = type === 'checkbox';
    if (currentType === 'composer') {
      if (name === 'projectname') {
        setShowSuggestion(true);
        if (typeof projectList !== 'undefined') {
          const suggestData: any = projectList
            .filter((k: any) => {
              return k.project_type === 1 || k.project_type === null;
            })
            .filter((suggestion: any) => suggestion.name.toLowerCase().includes(value.toLowerCase()));
          const suggest1 = suggestData.filter((k: any) => k.project_type === null);

          if (suggest1.length > 0) {
            setFormData((prev: any) => ({
              ...prev,
              environment: suggest1[0].meta[0].meta.environment_name,
            }));
            formData.environment = suggest1[0].meta[0].meta.environment_name;

            if (value === '') {
              setFormData((prev: any) => ({
                ...prev,
                environment: '',
              }));
              formData.environment = '';
            }
          } else {
            setFormData((prev: any) => ({
              ...prev,
              environment: '',
            }));
            formData.environment = '';
          }
          setSuggestions(suggestData);
          getEnvProject(value);
        }
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleComposerProjectClick = (text: string): void => {
    setShowSuggestion(false);
    setFormData({ ...formData, projectname: text });
    const unassign = projectList.find((kkk: any) => {
      return kkk.name === text;
    });
    if (unassign !== undefined) {
      setFormData((prev: any) => ({
        ...prev,
        environment: unassign.meta[0].meta.environment_name,
      }));
    }
    getEnvProject(text);
  };

  const handleWpChange = (event: any) => {
    // const { name, value, type } = event.target;
    const { name, value } = event.target;
    // const isCheckbox = type === 'checkbox';
    if (currentType === 'wp-connection') {
      if (name === 'projectname') {
        setShowSuggestion(true);

        if (typeof projectList !== 'undefined') {
          const suggestData: any = projectList
            .filter((k: any) => {
              return k.project_type === 2 || k.project_type === null;
            })
            .filter((suggestion: any) => suggestion.name.toLowerCase().includes(value.toLowerCase()));
          const suggest1 = suggestData.filter((k: any) => k.project_type === null);
          if (suggest1.length > 0) {
            setWpProjectDetails((prev: any) => ({
              ...prev,
              environment: suggest1[0].meta[0].meta.environment_name,
            }));
            wpprojectDetails.environment = suggest1[0].meta[0].meta.environment_name;
            if (value === '') {
              setWpProjectDetails((prev: any) => ({
                ...prev,
                environment: '',
              }));
              wpprojectDetails.environment = '';
            }
          } else {
            setWpProjectDetails((prev: any) => ({
              ...prev,
              environment: '',
            }));
            wpprojectDetails.environment = '';
          }
          setSuggestions(suggestData);
          getEnvProject(value);
        }
      }
    }

    setWpProjectDetails({
      ...wpprojectDetails,
      [name]: value,
    });
  };

  const handleWpProjectClick = (text: string): void => {
    setShowSuggestion(false);
    setWpProjectDetails({ ...wpprojectDetails, projectname: text });
    const unassign = projectList.find((kkk: any) => {
      return kkk.name === text;
    });
    if (unassign !== undefined) {
      setWpProjectDetails((prev: any) => ({
        ...prev,
        environment: unassign.meta[0].meta.environment_name,
      }));
    }
    getEnvProject(text);
  };

  const getEnvProject = async (name: string): Promise<any> => {
    const envlist: any = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/getEnvByProject`,
      method: 'POST',
      data: {
        projectName: name,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    setExistingEnvList(envlist.data);
    return envlist;
  };

  const handleSubmit = async (event: any, projectType: any) => {
    event.preventDefault();
    if (projectType === 'Composer') {
      let data1 = {
        name: formData.projectname,
        slug: formData.projectname,
        description: formData.description,
        environment: formData.environment,
        meta: JSON.stringify(fileMeta),
        composer_require_array: JSON.stringify(compRequireArray),
        composer_file: composerFileSent,
        // project_type:"1",
        // composer:formData.composerData
      };
      let payload: any = {
        id: 1,
        data: data1,
      };
      const formData1 = new FormData();

      // Append the 'id' parameter as a field
      formData1.append('id', payload.id);

      // Append fields from the 'data' parameter
      for (const key in payload.data) {
        if (payload.data.hasOwnProperty(key)) {
          formData1.append(key, payload.data[key]);
        }
      }

      axios({
        url: `${process.env.REACT_APP_API_URL}/api/team/${payload.id}/project`,
        method: 'POST',
        data: formData1,
        headers: {
          // Accept: "multipart/form-data",
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(res1 => {
          if (Object.keys(res1).length > 0) {
            if (res1.data.hasOwnProperty('error')) {
              toast.error(`${res1.data.message}`);
            } else {
              toast.success(`${res1.data.message}`);
            }
          }
        })
        .catch(err1 => {
          console.log('err1', err1);
        });

      // CreateProject(payload)
      //   .then((res: any) => {
      //     if (Object.keys(res).length > 0) {
      //       if (res.data.hasOwnProperty("error")) {
      //         toast.error(`${res.data.message}`);
      //         setIsProjectAdd(true);
      //       } else {
      //         toast.success(`${res.data.message}`);
      //       }
      //     }
      //   })
      //   .catch((err: any) => {
      //     toast.error(`${err}`);
      //   });
    } else {
      if (await check(wpprojectDetails.siteurl, wpprojectDetails.username, wpprojectDetails.app_password)) {
        const wpMeta = {
          type: 'wp',
          last_checked: new Date().toISOString(),
          username: wpprojectDetails.username,
          app_password: btoa(wpprojectDetails.app_password),
        };
        let data1 = {
          name: wpprojectDetails.projectname,
          slug: wpprojectDetails.projectname,
          description: wpprojectDetails.description,
          environment: wpprojectDetails.environment,
          project_type: 2,
          siteurl: wpprojectDetails.siteurl,
          username: wpprojectDetails.username,
          app_password: btoa(wpprojectDetails.app_password),
          meta: JSON.stringify(wpMeta),
          composer_require_array: JSON.stringify([]),
        };
        let payload: any = {
          id: 1,
          data: data1,
        };
        const formData1 = new FormData();

        // Append the 'id' parameter as a field
        formData1.append('id', payload.id);

        // Append fields from the 'data' parameter
        for (const key in payload.data) {
          if (payload.data.hasOwnProperty(key)) {
            formData1.append(key, payload.data[key]);
          }
        }
        axios({
          url: `${process.env.REACT_APP_API_URL}/api/team/${payload.id}/project`,
          method: 'POST',
          data: formData1,
          headers: {
            // Accept: "multipart/form-data",
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then(res1 => {
            if (Object.keys(res1).length > 0) {
              if (res1.data.hasOwnProperty('error')) {
                toast.error(`${res1.data.message}`);
              } else {
                toast.success(`${res1.data.message}`);
              }
            }
          })
          .catch(err1 => {
            console.log('err1', err1);
          });
        // CreateProject(payload)
        //   .then((res: any) => {
        //     if (Object.keys(res).length > 0) {
        //       if (res.data.hasOwnProperty("error")) {
        //         toast.error(`${res.data.message}`);
        //       } else {
        //         toast.success(`${res.data.message}`);
        //       }
        //     }
        //   })
        //   .catch((err: any) => {
        //     toast.error(`${err}`);
        //   });
      } else {
        toast.error(`Invalid credentials ...`);
      }
    }
  };
  useEffect(() => {
    setSearchParams({ type: activeTab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className='main'>
      <div className='container-fluid'>
        <div className='store_listab'>
          <ul className='nav nav-tabs' id='myTab' role='tablist'>
            <li className='nav-item m-0' role='presentation'>
              <button
                className={activeTab === 'wp-connection' ? 'nav-link active' : 'nav-link'}
                id='wp-connection-tab'
                data-bs-toggle='tab'
                data-bs-target='#wp-connection'
                type='button'
                role='tab'
                aria-controls='wp-connection'
                aria-selected='true'
                onClick={() => handleTabChange('wp-connection')}
              >
                Site Authentication
              </button>
            </li>
            <li className='nav-item m-0' role='presentation'>
              <button
                className={activeTab === 'composer' ? 'nav-link active' : 'nav-link'}
                id='composer-tab'
                data-bs-toggle='tab'
                data-bs-target='#composer'
                type='button'
                role='tab'
                aria-controls='composer'
                aria-selected='false'
                onClick={() => handleTabChange('composer')}
              >
                Composer Auth
              </button>
            </li>
            <li className='nav-item m-0' role='presentation'>
              <button
                className={activeTab === 'ftp' ? 'nav-link active' : 'nav-link'}
                id='ftp-tab'
                data-bs-toggle='tab'
                data-bs-target='#ftp'
                type='button'
                role='tab'
                aria-controls='ftp'
                aria-selected='false'
                onClick={() => {
                  handleTabChange('ftp');
                  handleShow();
                }}
              >
                FTP
              </button>
              <CommonModal show={showModal} onHide={handleClose}>
                <CommonModal.Header closeButton></CommonModal.Header>
                <CommonModal.Body>
                  <div>{'Under Development'}</div>
                </CommonModal.Body>
              </CommonModal>
            </li>
            <li className='nav-item m-0' role='presentation'>
              <button
                className={activeTab === 'ssh' ? 'nav-link active' : 'nav-link'}
                id='ssh-tab'
                data-bs-toggle='tab'
                data-bs-target='#ssh'
                type='button'
                role='tab'
                aria-controls='ssh'
                aria-selected='false'
                onClick={() => {
                  handleTabChange('ssh');
                  handleShow();
                }}
              >
                SSH
              </button>
              {/* <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div>{'Under Development'}</div>
                </Modal.Body>
              </Modal> */}
            </li>
          </ul>
          <div className='tab-content'>
            <div
              className={activeTab === 'wp-connection' ? 'tab-pane active' : 'tab-pane'}
              id='wp-connection'
              role='tabpanel'
              aria-labelledby='wp-connection-tab'
            >
              <div className='py-3'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <div className='text-end d-flex justify-content-end'>
                      <button className='cblue_btn d-flex align-items-center'>
                        <span className='d-inline-block ms-1'>Update project</span>
                      </button>
                      <button className='orange_btn d-flex align-items-center ms-2'>
                        <span className='d-inline-block ms-1'>
                          <svg
                            width='19'
                            height='22'
                            viewBox='0 0 19 22'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9.37922 0.006C9.43122 0.029 9.47922 0.058 9.52822 0.081C9.68766 0.146568 9.82456 0.257127 9.92223 0.399185C10.0199 0.541243 10.0741 0.708658 10.0782 0.881C10.0892 1.168 10.0842 1.455 10.0782 1.742C10.0782 1.863 10.1122 1.914 10.2382 1.942C11.5176 2.15505 12.7016 2.75346 13.6318 3.65727C14.5621 4.56109 15.1944 5.72727 15.4442 7C15.5249 7.4596 15.5654 7.92537 15.5652 8.392C15.5882 9.362 15.5482 10.332 15.6052 11.292C15.6503 12.0819 15.8535 12.8547 16.2027 13.5646C16.5519 14.2746 17.04 14.9072 17.6382 15.425C17.8205 15.5645 17.9731 15.7389 18.0871 15.9381C18.2011 16.1373 18.2742 16.3572 18.3022 16.585C18.3283 16.8064 18.3076 17.0307 18.2415 17.2436C18.1754 17.4564 18.0654 17.653 17.9185 17.8207C17.7717 17.9884 17.5913 18.1234 17.389 18.2169C17.1867 18.3105 16.9671 18.3606 16.7442 18.364C16.6869 18.3669 16.6295 18.3669 16.5722 18.364H1.74422C1.47535 18.3798 1.20666 18.3301 0.961238 18.2191C0.715816 18.1082 0.500994 17.9393 0.335225 17.727C0.0792197 17.3945 -0.0359366 16.9748 0.0145664 16.5583C0.0650694 16.1417 0.277189 15.7617 0.605225 15.5C1.20679 14.9756 1.70413 14.3426 2.07122 13.634C2.53004 12.7368 2.76408 11.7416 2.75322 10.734C2.75322 9.85 2.74222 8.972 2.75922 8.088C2.79652 6.6108 3.34384 5.19201 4.30827 4.07246C5.2727 2.95291 6.59482 2.20158 8.05022 1.946C8.21622 1.917 8.26222 1.854 8.25622 1.699C8.24522 1.527 8.26722 1.355 8.25022 1.182C8.20367 0.933726 8.24889 0.676994 8.37747 0.459567C8.50605 0.24214 8.70924 0.0788233 8.94922 0C9.09222 0.006 9.23622 0.006 9.37922 0.006Z'
                              fill='white'
                            />
                            <path
                              d='M5.85547 19.286H12.4625C12.4175 20.586 10.8265 21.941 9.30347 21.998C8.50011 22.0299 7.71168 21.7744 7.07974 21.2773C6.4478 20.7803 6.01373 20.0743 5.85547 19.286Z'
                              fill='white'
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='py-3'>
                <form className='addproject' onSubmit={e => handleSubmit(e, 'Wp-conector')}>
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>Project Name</span>
                    <input
                      type='text'
                      className='form-control'
                      autoComplete='off'
                      placeholder='Enter project name'
                      name='projectname'
                      onChange={handleWpChange}
                      value={wpprojectDetails.projectname}
                    />
                    <div
                      className='d-none'
                      style={{
                        flex: '0 0 100%',
                        maxWidth: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {showSuggestion && (
                        <>
                          {suggestions.length > 0 ? (
                            <ul
                              style={{
                                background: 'white',
                                color: 'black',
                                listStyle: 'none',
                                cursor: 'pointer',
                                flex: '0 0 calc(100% - 250px)',
                                maxWidth: 'calc(100% - 250px)',
                                paddingBottom: '10px',
                                paddingTop: '10px',
                              }}
                            >
                              {suggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleWpProjectClick(suggestion.name)}>
                                  {suggestion.name} &nbsp;{' '}
                                  {suggestion.project_type === 2 ? (
                                    <p style={{ color: 'blue' }}>REST API Connection</p>
                                  ) : (
                                    <p style={{ color: 'red', fontSize: '14px' }}> unassigned</p>
                                  )}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p
                              style={{
                                background: 'white',
                                color: 'black',
                                listStyle: 'none',
                                cursor: 'pointer',
                                flex: '0 0 calc(100% - 250px)',
                                maxWidth: 'calc(100% - 250px)',
                                // padding:"4px"
                              }}
                              onClick={() => handleWpProjectClick(wpprojectDetails.projectname)}
                            >
                              {/* {wpprojectDetails.projectname.length} */}
                              {wpprojectDetails.projectname}{' '}
                              {wpprojectDetails.projectname.length > 0 ? (
                                <b
                                  style={{
                                    color: 'orange',
                                    padding: '4px',
                                    background: '#d8ecf0',
                                    borderRadius: '12px',
                                  }}
                                >
                                  new project
                                </b>
                              ) : null}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>Project Description</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Description'
                      name='description'
                      onChange={handleWpChange}
                      value={wpprojectDetails.description}
                    />
                  </div>
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>Environment Type</span>
                    {/* <select className="form-select" id="inputGroupSelect01" placeholder='Select environment type' name="environment" onChange={handleWpChange} value={wpprojectDetails.environment}>
                        <option value="1">Production</option>
                        <option value="2">Staging</option>
                        <option value="3">QA</option>
                    </select> */}
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Environment'
                      name='environment'
                      onChange={handleWpChange}
                      value={wpprojectDetails.environment}
                    />
                  </div>
                  {existingEnvList.length > 0 ? (
                    <>
                      <p style={{ color: 'white' }}>Environment :</p> &nbsp;
                      {existingEnvList.map((ik: any) => {
                        return (
                          <button className='orange_btn m-3' type='button'>
                            {ik}
                          </button>
                        );
                      })}
                    </>
                  ) : null}
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>Site URL</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='https://'
                      name='siteurl'
                      onChange={handleWpChange}
                      value={wpprojectDetails.siteurl}
                    />
                  </div>
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>User Name</span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='username'
                      name='username'
                      onChange={handleWpChange}
                      value={wpprojectDetails.username}
                    />
                  </div>
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>Application Password</span>
                    <input
                      type='password'
                      className='form-control'
                      placeholder='password'
                      name='app_password'
                      onChange={handleWpChange}
                      value={wpprojectDetails.app_password}
                    />
                  </div>
                  <div className='d-flex justify-content-end'>
                    {/* <button className="cblue_btn_secondary mx-1">Generate additional key/token pair</button> */}
                    <button className='cblue_btn mx-1'>Authorize</button>
                    <button className='orange_btn ms-1'>Save</button>
                  </div>
                </form>
              </div>
              <div className='py-3'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <div className='text-end d-flex justify-content-end'>
                      {/* <button className="orange_btn mx-1">Disconnect environment</button> */}
                      {/* <button className="cblue_btn ms-1">Add environment</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={activeTab === 'composer' ? 'tab-pane active' : 'tab-pane'}
              id='composer'
              role='tabpanel'
              aria-labelledby='composer-tab'
            >
              <div className='py-3'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <button className='grey_btn d-flex align-items-center' onClick={() => navigate('/projects/import')}>
                      <span className='d-inline-block ms-1'>
                        <svg
                          id='Layer_1'
                          height='22'
                          width='19'
                          data-name='Layer 1'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 122.88 108.06'
                        >
                          <path d='M63.94,24.28a14.28,14.28,0,0,0-20.36-20L4.1,44.42a14.27,14.27,0,0,0,0,20l38.69,39.35a14.27,14.27,0,0,0,20.35-20L48.06,68.41l60.66-.29a14.27,14.27,0,1,0-.23-28.54l-59.85.28,15.3-15.58Z' />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className='select_login_screen addproject'>
                <div className='login_register'>
                  <span>composer.json - upload</span>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={checkbox}
                      id='select_login_screen'
                      name='select_login_screen'
                      onChange={e => setCheckbox(!checkbox)}
                    />
                    <span className='slider round'></span>
                  </label>
                  <span>composer.json - repository</span>
                </div>
              </div>
              {!checkbox ? (
                <div className='py-3 c-upload'>
                  <form className='addproject' onSubmit={e => handleSubmit(e, 'Composer')}>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Name</span>
                      <input
                        type='text'
                        className='form-control'
                        autoComplete='off'
                        placeholder='Enter project name'
                        name='projectname'
                        onChange={handleChange}
                        value={formData.projectname}
                      />
                      <br />
                      <div
                        className='d-none'
                        style={{
                          flex: '0 0 100%',
                          maxWidth: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {showSuggestion && (
                          <>
                            {suggestions.length > 0 ? (
                              <ul
                                style={{
                                  background: 'white',
                                  color: 'black',
                                  listStyle: 'none',
                                  cursor: 'pointer',
                                  flex: '0 0 calc(100% - 250px)',
                                  maxWidth: 'calc(100% - 250px)',
                                  paddingBottom: '10px',
                                  paddingTop: '10px',
                                }}
                              >
                                {suggestions.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    onClick={() => handleComposerProjectClick(suggestion.name)}
                                    style={{
                                      background: 'white',
                                      color: 'black',
                                      padding: '5px 0px',
                                    }}
                                  >
                                    <p>
                                      {suggestion.name} &nbsp;{' '}
                                      {suggestion.project_type === 1 ? (
                                        <b
                                          style={{
                                            color: 'green',
                                            padding: '4px',
                                            background: '#d8ecf0',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          composer
                                        </b>
                                      ) : (
                                        <b
                                          style={{
                                            color: 'red', fontSize: '14px',
                                            padding: '4px',
                                            background: '#d8ecf0',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          {' '}
                                          unassigned
                                        </b>
                                      )}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p
                                style={{
                                  background: 'white',
                                  color: 'black',
                                  listStyle: 'none',
                                  cursor: 'pointer',
                                  flex: '0 0 calc(100% - 250px)',
                                  maxWidth: 'calc(100% - 250px)',
                                  // padding:"10px"
                                }}
                                onClick={() => handleComposerProjectClick(formData.projectname)}
                              >
                                {formData.projectname}{' '}
                                {formData.projectname.length > 0 ? (
                                  <b
                                    style={{
                                      color: 'orange',
                                      padding: '4px',
                                      background: '#d8ecf0',
                                      borderRadius: '12px',
                                    }}
                                  >
                                    new project
                                  </b>
                                ) : null}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Description</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Description'
                        name='description'
                        onChange={handleChange}
                        value={formData.description}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Environment Type</span>
                      {/* <select className="form-select" id="inputGroupSelect02" placeholder='Select environment type' name="environment" onChange={handleChange} value={formData.environment}>
                            <option value="1">Production</option>
                            <option value="2">Staging</option>
                            <option value="3">QA</option>
                        </select> */}
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter Environment'
                        name='environment'
                        onChange={handleChange}
                        value={formData.environment}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      {existingEnvList.length > 0 ? (
                        <>
                          <p style={{ color: 'white' }}>Environment :</p> &nbsp;
                          {existingEnvList.map((ik: any) => {
                            return (
                              <button className='orange_btn m-3' type='button'>
                                {ik}
                              </button>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                    <div className='d-flex mb-3'>
                      <input className='form-control d-none' type='file' id='formFile' onChange={handleFileChange} />
                      <input
                        className={
                          !isFileAdded
                            ? 'form-control'
                            : isFileAdded === 'valid'
                            ? 'form-control green_border'
                            : 'form-control red_border'
                        }
                        type='text'
                        value={file}
                        readOnly
                      />
                      <button type='button' className='ms-2 file-btn'>
                        <label htmlFor='formFile'>Browse</label>
                      </button>
                    </div>
                    <div className='d-flex justify-content-end my-5'>
                      {isProjectAdd ? (
                        <button className='cblue_btn_secondary mx-1'>Continue to Environment</button>
                      ) : isFileAdded === 'valid' ? (
                        <button className='green_btn mx-1'>Add environment</button>
                      ) : (
                        <button disabled className='cblue_btn mx-1'>
                          Add environment
                        </button>
                      )}
                      <button type='button' className='cblue_btn ms-1'>
                        Update connection
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className='py-3 c-repo'>
                  <form className='addproject '>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Name</span>
                      <input type='text' className='form-control' placeholder='Enter project name' />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Description</span>
                      <input type='text' className='form-control' placeholder='Description' />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Environment Type</span>
                      {/* <select
                        className="form-select"
                        id="inputGroupSelect02"
                        // placeholder="Select environment type"
                      >
                        <option value="1">Production</option>
                        <option value="2">Staging</option>
                        <option value="3">QA</option>
                      </select> */}
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>SSH User</span>
                      <input type='text' className='form-control' placeholder='username' />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>SSH Password</span>
                      <input type='password' className='form-control' placeholder='password' />
                    </div>
                    <div className='d-flex justify-content-end my-5'>
                      <button className='cblue_btn mx-1'>Add environment</button>
                      <button className='orange_btn ms-1'>Update connection</button>
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div
              className={activeTab === 'ftp' ? 'tab-pane active' : 'tab-pane'}
              id='ftp'
              role='tabpanel'
              aria-labelledby='ftp-tab'
            >
              <FTPSSH />
            </div>
            <div
              className={activeTab === 'ssh' ? 'tab-pane active' : 'tab-pane'}
              id='ssh'
              role='tabpanel'
              aria-labelledby='ssh-tab'
            >
              <FTPSSH />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AddProject;
