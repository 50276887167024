// numberSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queuevalue: 0, // Default value of the number
  deployvalue: 0,
  wishlistvalue: 0,
  plugincount:0,
  notificationAlert:{}
};

const numberSlice = createSlice({
  name: 'number',
  initialState,
  reducers: {
    setQueueNumber: (state, action) => {
      state.queuevalue = action.payload; // Store the passed number in the Redux state
    },
    setDeployNumber: (state, action) => {
      state.deployvalue = action.payload; // Store the passed number in the Redux state
    },
    setWishlistNumber: (state, action) => {
      state.wishlistvalue = action.payload; // Store the passed number in the Redux state
    },
    setPluginNumber:(state,action) => {
      state.plugincount = action.payload
    },
    setNotificationAlert:(state,action) => {
      state.notificationAlert = action.payload
    }
  },
});

export const { setQueueNumber, setDeployNumber, setWishlistNumber,setPluginNumber,setNotificationAlert } = numberSlice.actions;
export default numberSlice.reducer;
