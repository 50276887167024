import React, { SetStateAction, useEffect, useState } from 'react'
import img from '../img/store-thumbnail.svg';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setPluginNumber, setWishlistNumber } from '../features/notifyNumbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';

interface WishlistItemInterface {
    plugin:any;
    wishlist:Array<any>;
    setWishlist:React.Dispatch<SetStateAction<any>>;
    isSelectedActive:boolean;
    selectPluginData:Array<any>;
    setSelectPluginData:React.Dispatch<SetStateAction<Array<any>>>;
    dataType:string
}

const WishlistItem:React.FC<WishlistItemInterface> = ({plugin,wishlist,setWishlist,isSelectedActive,selectPluginData,setSelectPluginData,dataType}) =>  {
  const dataType1 = dataType === 'plugin' ?`https://ps.w.org/${plugin.slug}/assets/icon-256x256.png`: `https://i0.wp.com/themes.svn.wordpress.org/${plugin.slug}/${plugin.version}/screenshot.png`
  const [src,setSrc] =useState(dataType1);
  const navigate =useNavigate()
  const dispatch = useDispatch()

  const handleRemove = (id:any) => {
    // console.log("wishlist",wishlist,id,dataType)
    const wishlist1 = JSON.parse(localStorage.getItem('wishlist')!)
      const updatedWishlist = wishlist1.filter((item:any) => {return !(item.id === id && item.dataType ===dataType)});
      // console.log(updatedWishlist)
      const getWishlist =updatedWishlist.filter((item:any) => {return item.dataType === dataType})
      const dataType2 ='wishlist'
      localStorage.setItem(dataType2, JSON.stringify(updatedWishlist));
      setWishlist(getWishlist);
      dispatch(setWishlistNumber(updatedWishlist.length))
    };
      // const storePlugins = localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
      // const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);


const handleImageError = () => {
    setSrc(img)
 }

 const onAddPlugin = (plugin: any, event: any): void => {
  const a = selectPluginData;
  const filterdata = selectPluginData.find((i: any) => {
    return i.id === plugin.id;
  });
  if (filterdata !== undefined) {
    const ab = a.filter((i: any) => {
      return i.id !== plugin.id;
    });
    localStorage.setItem('storequeue', JSON.stringify(ab!));
    dispatch(setPluginNumber(ab.length))
    toast.error('Plugin removed from queue')
    setSelectPluginData(ab);
  } else {
    const abc = [...a, plugin];
    localStorage.setItem('storequeue', JSON.stringify(abc!));
    toast.success('Plugin added to queue')
    dispatch(setPluginNumber(abc.length))
    setSelectPluginData(abc);
  }
};
// console.log("selectPluginData",selectPluginData)
const isPremium = plugin ? plugin?.versions?.some((obj:any) => {return obj.fusepress_path !== null})?'Premium':'Public' : 'Public'
const NavigateTo = (plugin:any) => {
  navigate(`/store/${plugin.slug}`)
}

const renderRating = () => {
  if (plugin.type === 'private') {
    return (
      <div className='tooltip-container'>
        <div className='tooltip'>Private</div>
      </div>
      //   {/* <div className="price">${plugin.rating}</div> */}
      //   <Rating tooltipDefaultText="Private" showTooltip={true} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" allowHover={false}/>
    );
  } else {
    return (
      <div aria-disabled={true}>
        {/* <div className="price">${plugin.rating}</div> */}
        <Rating
          initialValue={plugin.ratings / 20}
          allowFraction={true}
          fillColor='#F28A03'
          size={20}
          className='float-start'
          emptyColor='#888888'
          allowHover={false}
          disableFillHover={true}
          readonly
        />
      </div>
    );
  }
};

  return (
    <div className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 mt-2" onClick={() => NavigateTo(plugin)}>
                <span  className="plug_grid" style={{cursor:'pointer'}}>
                  <div className="plugins_info">
                    <div className="plugins_item_header">
                      <div className="plugin_icon">
                        <img className='img-fluid' src={src} onError={() => handleImageError()} alt="plugin_icon" />
                      </div>
                      <div className="plugins_title_author">
                        <div className="plugins_item_title" title={plugin.name ? plugin.name : plugin.slug}><h6>{plugin.name ? plugin.name.substr(0,20) +'...' : plugin.slug}</h6></div>
                        <div className="plugins_item_author"><p>by <span className="plugins_item_author_name">{plugin.wp_author}</span></p></div>
                       {/* <div className={`product_like ${isFavorite ? 'favorite' : ''}`} style={{width:'35px',height:'35px'}}  onClick={(e) =>{
                    e.stopPropagation()
                    addToWishlist()}}>
                          <FontAwesomeIcon icon={faHeart}  style={{color:'white'}} size='lg' />
                     </div> */}
                     <div className="plugins_item_ratings">
                     {renderRating()}
                        {/* <svg className="star-svg" stroke="currentColor" fill="#f08921" stroke-width="0" viewBox="0 0 24 24" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>
                        <span className="plugins_item_rating_value"><p>4.6</p></span> */}
                      </div>
                      </div>
                    </div>
                    <div className="plugins_item_description">
                      {/* <p style={{width:'35%'}} dangerouslySetInnerHTML={{__html:plugin.description}}></p> */}
                      <p>All the benefits of Yoast SEO Free as well as exclusive SEO features which unlock even more tools and functionality.</p>
                    </div>
                    <div style={{height:'30px'}}>
                      {plugin.last_updated !== null && dataType !== 'theme' ? <span className='text-white'>Last Updated:<span style={{color:"#f08921"}}>&nbsp;{plugin.last_updated}</span></span> : null}
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_pricing"><p>{isPremium}</p></div>
                    <div className="plugins_item_additional_info " style={{display:'flex'}}>
                    {dataType === 'plugins'  &&
                      <div className='plugin_select_btn me-2' onClick={e => {
                            e.stopPropagation()
                            onAddPlugin(plugin, e)
                          }}>
                        <FontAwesomeIcon icon={isSelectedActive ?faCircleMinus: faCirclePlus} size='xl' color={isSelectedActive? "#fd2525" :'#f08921'}/>
                        </div>}
                        <div className='plugin_select_btn' onClick={e => {
                            e.stopPropagation()
                            handleRemove(plugin.id)
                          }}>
                        <FontAwesomeIcon icon={faTrash} size='xl' color='#fd2525'/>
                        </div>
                    </div>


                  </div>
                </span>
              </div>

  //   <div key={plugin.id} className='col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns' onClick={() =>NavigateTo(plugin)}>
  //   <div className='plug_grid'>
  //     <div className='card-body'>
  //       <div className='plugin_area'>
  //         <div className='plug_img'>

  //     <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} onError={() => handleImageError()} alt=''></img>
  //         </div>
  //         <div className='list'>

  //           <Link to={`/store/${plugin.slug}`} style={{ textDecoration: 'none' }}>
  //             <div className='pluginname font20'>{plugin.name}</div>
  //             </Link>


  //           <div className='author font16'>By:{plugin.wp_author}</div>
  //           <div className='up_date font16'>
  //             <span className='text-white font16'>Last updated : </span>
  //             { moment(plugin.updated_at).format('DD MMM YYYY')}
  //           </div>
  //           <div className='pricesection'>
  //             <div className='row' style={{marginBottom:'5px'}}>
  //               <div className='col-12 col-md-6'>
  //                 <div aria-disabled='true'>
  //                   <span className='style-module_starRatingWrap__q-lJC' style={{ direction: 'ltr' }}>
  //                     <span className='style-module_simpleStarRating__nWUxf float-start' aria-hidden='true'>
  //                       <span
  //                         className='style-module_emptyIcons__Bg-FZ empty-icons'
  //                         style={{ color: 'rgb(136, 136, 136)' }}
  //                       >
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                       </span>
  //                       <span
  //                         className='style-module_fillIcons__6---A filled-icons'
  //                         title='4.7 out of 5'
  //                         style={{ left: '0px', color: 'rgb(242, 138, 3)', width: '94%' }}
  //                       >
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                         <svg
  //                           className='star-svg'
  //                           stroke='currentColor'
  //                           fill='currentColor'
  //                           stroke-width='0'
  //                           viewBox='0 0 24 24'
  //                           width='20'
  //                           height='20'
  //                           xmlns='http://www.w3.org/2000/svg'
  //                         >
  //                           <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
  //                         </svg>
  //                       </span>
  //                     </span>
  //                   </span>
  //                 </div>
  //               </div>

  //             </div>

  //                 <div className='plugin_select_btn' style={{width:'100%', display:'flex', justifyContent:"space-between"}}>
  //                   <button className='cblue_btn' type='button' style={{ zIndex: '2147483647' }}  onClick={(e) => {
  //                     e.stopPropagation()
  //                     handleRemove(plugin.id)
  //                   }}>
  //                     Remove
  //                   </button>
  //                   {dataType === 'plugins'  &&
  //                 <button className='orange_btn ms-auto' type='button' style={{ zIndex: '2147483647' }}  onClick={e => {
  //                   e.stopPropagation()
  //                   onAddPlugin(plugin, e)
  //                 }}>
  //                     {isSelectedActive === true ? "Remove from queue" : "Add to queue"}
  //                   </button>}
  //                 </div>

  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  )
}

export default WishlistItem
