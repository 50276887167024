import React from 'react';


interface SpinnerProps {
   left?:any;
   top?:any;
   bottom?:any;
  }

const Spinner: React.FC<SpinnerProps> = ({left,top, bottom}) => {
  return (
    <div style={{width:'70px', height:'70px', zIndex:'9999999999', position:'absolute', left:left, top:top, bottom:bottom}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        width="100%"
        height="100%"
        style={{
          shapeRendering: 'auto',
          display: 'block',
          background: 'transparent',
        }}
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <rect fill="#f08921" height="40" width="10" y="30" x="15">
            <animate
              begin="-0.6"
              values="1;0.2;1"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
              keyTimes="0;0.5;1"
              calcMode="spline"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            ></animate>
          </rect>
          <rect fill="#f08921" height="40" width="10" y="30" x="35">
            <animate
              begin="-0.4"
              values="1;0.2;1"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
              keyTimes="0;0.5;1"
              calcMode="spline"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            ></animate>
          </rect>
          <rect fill="#f08921" height="40" width="10" y="30" x="55">
            <animate
              begin="-0.2"
              values="1;0.2;1"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
              keyTimes="0;0.5;1"
              calcMode="spline"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            ></animate>
          </rect>
          <rect fill="#f08921" height="40" width="10" y="30" x="75">
            <animate
              begin="-1"
              values="1;0.2;1"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
              keyTimes="0;0.5;1"
              calcMode="spline"
              repeatCount="indefinite"
              dur="1s"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
      </svg>
    </div>
  );
};

export default Spinner;
