/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import WishlistItem from './WishlistItem';
import backarrow from '../img/acc-arw.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWishlistNumber } from '../features/notifyNumbers';
import { t } from 'i18next';

const Wishlist: React.FC = () => {
  const [wishlist, setWishlist] = useState([]);
  const [wishlistTheme, setWishlistTheme] = useState([]);
  const storePlugins =
    localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
  const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const fetchedWishlist = wishlistString ? JSON.parse(wishlistString) : [];
    const allPlugin = fetchedWishlist.filter((ik: any) => {
      return ik.dataType === 'plugins';
    });
    const allTheme = fetchedWishlist.filter((ik: any) => {
      return ik.dataType === 'theme';
    });
    setWishlist(allPlugin);

    setWishlistTheme(allTheme);
  }, []);
  // console.log('wishlist', wishlist);

  const goBack = () => {
    navigate(-1);
  };

  const clearAll = () => {
    const wishlist: any = [];
    setWishlist([]);
    setWishlistTheme([]);
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
    dispatch(setWishlistNumber(wishlist.length));
  };

  return (
    <main className='main' style={{ padding: '60px', marginTop: '60px' }}>
      {/* <div style={{marginBottom:'40px'}}>
        <button type='button' className='cblue_btn back_btn' onClick={goBack}>
                            <span className='back_btn_icon' style={{marginRight:'5px'}}>
                              <img className='img-fluid' src={backarrow} alt='back-btn' />
                            </span>
                            <span>Back</span>
                        </button>
        </div> */}
      {/* <nav>
        <div
          className='nav nav-tabs justify-content-between'
          id='nav-tab'
          role='tablist'
          style={{ borderBottom: '2px solid #202020' }}
        >
          <div className='d-flex'>
            <button
              className='nav-link active'
              id='nav-home-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-home'
              type='button'
              role='tab'
              aria-controls='nav-home'
              aria-selected='true'
            >
              All Plugins
            </button>
            <button
              className='nav-link'
              id='nav-profile-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-profile'
              type='button'
              role='tab'
              aria-controls='nav-profile'
              aria-selected='false'
            >
              All Themes
            </button>
          </div>
          <div>
            <button type='button' className='orange_btn' style={{ marginBottom: '8px' }} onClick={() => clearAll()}>
              Remove All
            </button>
          </div>
        </div>
      </nav> */}

      <div className='store_listab'>
        <ul className='nav nav-tabs store-first store-first'>
          <li className='nav-item m-0'>
            <button className='nav-link active'
              id='nav-home-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-home'
              type='button'
              role='tab'
              aria-controls='nav-home'
              aria-selected='true'>
              Plugins
            </button>
          </li>
          <li className='nav-item m-0' role='presentation'>
            <button className='nav-link'
              id='nav-profile-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-profile'
              type='button'
              role='tab'
              aria-controls='nav-profile'
              aria-selected='false'>
              Themes
            </button>
          </li>
          <div className='select_filter_group ms-auto'>
            <button type='button' className='orange_btn' style={{ marginBottom: '8px' }} onClick={() => clearAll()}>
              Remove All
            </button>
          </div>
        </ul>
      </div>

      <h1 className='text-white font20' style={{ marginTop: '20px' }}>
        Wishlist
      </h1>

      <div className='tab-content' id='nav-tabContent'>
        <div className='tab-pane fade show active' id='nav-home' role='tabpanel' aria-labelledby='nav-home-tab'>
          <div className='container_fluid'>
            <div className='plugins_list row plugin_row' style={{ marginTop: '25px' }}>
              {wishlist.map((item: any) => {
                const isSelectedActive =
                  selectPluginData.find((ik: any) => {
                    return ik.id === item.id;
                  }) !== undefined
                    ? true
                    : false;
                return (
                  <WishlistItem
                    plugin={item}
                    wishlist={wishlist}
                    setWishlist={setWishlist}
                    isSelectedActive={isSelectedActive}
                    selectPluginData={selectPluginData}
                    setSelectPluginData={setSelectPluginData}
                    dataType={'plugins'}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className='tab-pane fade' id='nav-profile' role='tabpanel' aria-labelledby='nav-profile-tab'>
          <div className='container_fluid'>
            <div className='plugins_list row plugin_row' style={{ marginTop: '25px' }}>
              {wishlistTheme.map((item: any) => {
                const isSelectedActive =
                  selectPluginData.find((ik: any) => {
                    return ik.id === item.id;
                  }) !== undefined
                    ? true
                    : false;
                return (
                  <WishlistItem
                    plugin={item}
                    wishlist={wishlistTheme}
                    setWishlist={setWishlistTheme}
                    isSelectedActive={isSelectedActive}
                    selectPluginData={selectPluginData}
                    setSelectPluginData={setSelectPluginData}
                    dataType={'theme'}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> */}
      </div>
    </main>
  );
};

export default Wishlist;
