import { createSlice } from '@reduxjs/toolkit';

export const workspaceProjectSlice = createSlice({
  name: 'workspaceProject',
  initialState: {
    value: {
      team: null,
      project: null,
    },
  },
  reducers: {
    setProject: (state, action) => {
      state.value.team = action.payload.team;
      state.value.project = action.payload.project;
    },
  },
});

export const { setProject } = workspaceProjectSlice.actions;

export default workspaceProjectSlice.reducer;
