/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import '../sass/pages/new_store.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faHeart, faQuestion, faSearch, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { useFilterPluginQuery, useFilterPluginTagQuery } from '../features/api';
import Loader from './Loader';
import { FixedSizeList as List } from 'react-window';
import { useAppSelector } from '../features/hooks';
import FeaturedPluginCard from './FeaturedPluginCard';
import { useDispatch } from 'react-redux';
import { calculate, setSearch, setTag } from '../features/featuredStore';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Steps } from 'intro.js-react';
import { updateTrigger } from '../features/introArray';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';

interface FeaturedPluginInterface {
  plugin: Array<any>;
  premiumPlugin: Array<any>;
  theme: Array<any>;
}

const NewStore: React.FC = () => {
  const [featuredTag, setFeaturedTag] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allTags, setAllTags] = useState<Array<any>>([]);
  const [featuredPlugin, setFeaturedPlugin] = useState<Partial<FeaturedPluginInterface>>({});
  const filters = useAppSelector(state => state.featuredQuery.value);
  const { data: tagData } = useFilterPluginTagQuery({});
  const { data: featuredData, isLoading, isError } = useFilterPluginQuery({
    search: filters.search,
    tag: filters.tag,
  });
  const [isFixed, setIsFixed] = useState(false);
  const storePlugins =
    localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
  const [selectPluginData, setSelectPluginData] = useState<Array<any>>(storePlugins);
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const introArray = useAppSelector(state => state.introArray.arrayData);
  const introTrigger = introArray.find(ik => ik.page === 'featured-plugin')?.trigger;
  const [showOutsideTag, setShowOutSideTag] = useState<string>('')
  const [disablePrivatepack,setDisablePrivatepack] = useState<boolean>(true)
  const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  // console.log('introArray', introArray);
  const {t} = useTranslation()

  useEffect(() => {
    // console.log('introArray', introArray);
    if (introArray.length > 0) {
      const getspecificdata = introArray.find(ik => ik.page === 'featured-plugin');
      if (getspecificdata !== undefined) {
        if (getspecificdata.trigger === true) {
          setStepsEnabled(true);
        }
      }
    }
  }, [introTrigger]);

  const [activeTag, setActiveTag] = useState(null);

  useEffect(() => {
    if(userdetails !== null){
      if(Object.prototype.hasOwnProperty.call(userdetails,'user_subscription')){
        if(userdetails.user_subscription.length > 0){
          if(userdetails.user_subscription[0].premium_package === false){
            setDisablePrivatepack(true)
          } else {
            setDisablePrivatepack(false)
          }
        }
      }
    }

  },[userdetails])

  useEffect(() => {
    // console.log('tagData',tagData)
    if (typeof tagData !== 'undefined') {
      const featured = tagData.slice(0, 8);
      const tags = tagData.slice(8);
      if(filters.tag.length > 0){

        const TagFilter = tagData.filter((ik:any) => {return ik.tag === filters.tag})
        // console.log("TagFilter",TagFilter)
        if(TagFilter.length > 0) {
          const Tag1 = tags.filter((ikk:any) => {return ikk.tag === filters.tag})
          if(Tag1.length > 0){
            setShowOutSideTag(filters.tag)
          }
        } else {
          console.log('come here')
          setShowOutSideTag(filters.tag)
        }
      } else {
        setShowOutSideTag('')
      }
      setFeaturedTag(featured);
      setAllTags(tags);
    }
  }, [tagData,filters]);

  useEffect(() => {
    if (typeof featuredData !== 'undefined') {
      setFeaturedPlugin(featuredData);
    }
  }, [featuredData]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 290) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const searchFunc = () => {
    dispatch(setSearch(searchText));
  };

  const setReset = () => {
    window.history.pushState({}, document.title, window.location.pathname);
    setSearchText('');
    dispatch(calculate());
  };

  // const handleTagClick = (tag: any) => {
  //   dispatch(setTag(tag));
  //   setActiveTag(tag); // Set the active tag
  // };
  const handleTagClick = (tag:any) => {
    const currentActiveTag = filters.tag;
    if (currentActiveTag === tag) {
      dispatch(setTag(''));
      // setReset()
    } else {
      dispatch(setTag(tag));
      setActiveTag(tag);
    }
  };
  const routeToPremiumPlugin = () => {
    const params = createSearchParams({
      type: 'plugins',
      search: '',
      sort: 'popularity',
      perpage: '20',
      ratings: ['4', '5'],
      pg: '1',
      tag: '',
      vendor: '',
      rating: [],
      order: 'asc',
      datatype: 'public',
      project_id: '',
      env_id: '',
      redirect_to: '',
      flag: 'plugins',
      ispremium: 'true',
    });

    navigate({
      pathname: '/store',
      search: `?${params.toString()}`,
    });
    window.location.reload();
  };

  const routeToPlugin = () => {
    const params = createSearchParams({
      type: 'plugins',
      search: '',
      sort: 'popularity',
      perpage: '20',
      ratings: ['4', '5'],
      pg: '1',
      tag: '',
      vendor: '',
      rating: [],
      order: 'asc',
      datatype: 'public',
      project_id: '',
      env_id: '',
      redirect_to: '',
      flag: 'plugins',
      ispremium: 'false',
    });

    navigate({
      pathname: '/store',
      search: `?${params.toString()}`,
    });
    window.location.reload();
  };

  const routeToTheme = () => {
    const params = createSearchParams({
      type: 'plugins',
      search: '',
      sort: 'popularity',
      perpage: '20',
      ratings: [],
      pg: '1',
      tag: '',
      vendor: '',
      rating: [],
      order: 'asc',
      datatype: 'public',
      project_id: '',
      env_id: '',
      redirect_to: '',
      flag: 'theme',
      ispremium: 'false',
    });

    navigate({
      pathname: '/store',
      search: `?${params.toString()}`,
    });
    window.location.reload();
  };
  let initialSteps = 0;
  let ProjectsSteps = [
    {
      title: 'Welcome to Featured Plugin Page',
      // element: ".new-project-first",
      position: 'right',
      intro: 'You will explore here premium and non premium packages',
    },
    {
      title: 'Search Box',
      element: '.filterd-first',
      position: 'left',
      intro: 'Search plugin on press of enter or click of search icon',
    },
    {
      title: 'Popular Tags',
      element: '.filterd-five',
      position: 'left',
      intro: 'Click these popular tags you will see plugins related to that',
    },
    {
      title: 'Premium plugin',
      element: '.filtered-second',
      position: 'left',
      intro: 'You will explore top premium plugin and click of browse all you will be redirected to premium plugins',
    },
    {
      title: 'Plugins',
      element: '.filtered-third',
      position: 'left',
      intro: 'You will explore top plugin and click of browse all you will be redirected to plugins',
    },
    {
      title: 'Promotions',
      element: '.filtered-four',
      position: 'left',
      intro: 'You will get your product promoted here',
    },
    {
      title: 'Theme',
      element: '.filtered-six',
      position: 'left',
      intro: 'You will explore top themes and click of browse all you will be redirected to themes',
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'featured-plugin-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'featured-plugin-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'featured-plugin-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'featured-plugin-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'featured-plugin-screen';
      });
      if (index >= 0) {
        array[index].status = 'false';
        // manageQueryString(array[index].status)
        dispatch(updateTrigger({ page: 'projects', trigger: false }));
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };
  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'featured-plugin-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };
  const pathname = window.location.pathname;

  const handleNavigate = () => {
    navigate('/store');
    window.location.reload()
  };

  if (typeof tagData === 'undefined' && isLoading) {
    return <Loader />;
  }

  if(isError) {
    return <ErrorPage/>
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <>
        {pathname === '/featured-plugins' && (
          <Steps
            enabled={stepsEnabled}
            steps={ProjectsSteps}
            // onExit={() => (stepsEnabled === false ? false : stepsEnabled)}
            onExit={e => onIntroExit(e)}
            initialStep={initialSteps}
            options={{
              showProgress: true,
              // showStepNumbers: true,
            }}
            onAfterChange={() => {
              const tooltip = document.querySelector('.introjs-tooltip');
              if (tooltip) {
                const footer = document.createElement('div');
                footer.classList.add('customTooltipFooter');
                footer.innerHTML = `
                <span style="font-size:small; color:white; display:flex; justify-content: center;">Check More Info:&nbsp;
                  <a href="${process.env.REACT_APP_WORDPRESS_STAGING_URL}/documentation" style="font-size:small; color:#F28A03;" target="_blank" rel="noopener noreferrer";>
                    Documentation.
                  </a>
                </span>

              `;
                // Append the footer only if it's not already appended
                if (!tooltip.querySelector('.customTooltipFooter')) {
                  tooltip.appendChild(footer);
                }
              }
            }}
            // onBeforeExit={() => {
            //   let data =
            //     stepsEnabled !== null &&
            //     window.confirm("Don't want to show this again then press Ok");
            //   stepsEnabled !== null &&
            //   data &&
            //   localStorage.setItem("IntroToken", "false");
            // }}
          />
        )}
        <main className='main'>
          <div className='store_banner'>
            <div className='container'>
              <div className='store_header'>
                <h1 className='store_title text-white display-4 mb-4'>{t('new-store.title')}</h1>
                <p className='store_subtitle mb-5'>{t('new-store.subtitle')}</p>

                {/* <div className="store_search" style={{ display: "flex", maxWidth: "350px", right: "0px", }}>
                <div className="search d-flex">
                  <div className="search_main">
                    <input type="text" placeholder="Search here" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    <FontAwesomeIcon icon={faSearch} color='white' onClick={() => searchFunc()} />
                  </div>
                  <button type='button' className='orange_btn' onClick={() => setReset()}>Reset Filters</button>
                </div>
              </div> */}

                <div role='form' tabIndex={0} className='filterd-first'>
                  <div className='search-container d-flex' style={{ position: 'relative'}}>
                    <div className={`d-flex search_filterd_menu ${isFixed ? 'search-bar-sticky' : ''}`}>
                    <div className='d-flex search-bar'>
                      <div className='search_bar_inner'>
                      <div className='search_bar_input w-100 position-relative'>
                        <input
                          type='text'
                          placeholder='Search here'
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          onKeyDown={e => e.key === 'Enter' && searchFunc()}
                          aria-label='Search plugins'
                          className='form-control form-control-lg'
                          style={{ paddingRight: '60px', width: '100%', height: '55px', borderRadius: '0px' }}
                        />
                        <button
                          type='button'
                          onClick={() => searchFunc()}
                          style={{
                            position: 'absolute',
                            right: '20px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                          }}
                          aria-label='Search'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            fill='currentColor'
                            className='bi bi-search'
                            viewBox='0 0 16 16'
                          >
                            <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                          </svg>
                          <span className='visually-hidden'>{t('new-store.buttons.search')}</span>
                        </button>
                        </div>
                        {isFixed && (
                          <div
                            onClick={handleNavigate}
                            className='plugins_actions orange_btn'
                          >
                            <span>{t('new-store.buttons.browse')}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`filterd-five`}>
                      <div className={`d-flex flex-wrap gap-2`}>
                      {showOutsideTag.length > 0 &&
                              <button
                              className={`btn search_btn search_btn_active`} // Apply active class conditionally
                              type='button'
                              // onClick={() => handleTagClick(ik.tag)}
                            >
                              {capitalizeFirstLetter(showOutsideTag)}
                            </button>

                          }
                        {featuredTag.map(ik => {
                          const isActive = filters.tag === ik.tag; // Check if the current tag is active
                          return (
                            <button
                              key={ik.tag} // Make sure to add a key
                              className={`btn search_btn ${isActive ? 'search_btn_active' : ''}`} // Apply active class conditionally
                              type='button'
                              onClick={() => handleTagClick(ik.tag)}
                            >
                              {capitalizeFirstLetter(ik.value)}
                            </button>
                          );
                        })}
                        <div className='last_dropdown d-flex align-items-center mx-1'>
                          <div className='dropdown'>
                            <button
                              className='btn search_btn dropdown-toggle'
                              type='button'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              {t('new-store.buttons.more')}
                            </button>
                            <ul className='dropdown-menu' >
                              <List
                                height={280} // Height of the list container
                                itemCount={allTags.length} // Total number of items
                                itemSize={35} // Height of each row
                                width={300} // Width of the list container
                              >
                                {({ index, style }) => (
                                  <li key={allTags[index].tag}>
                                    <span
                                      className='dropdown-item'
                                      style={style}
                                      onClick={() => handleTagClick(allTags[index].tag)}
                                    >
                                      {capitalizeFirstLetter(allTags[index].value)}
                                    </span>
                                  </li>
                                )}
                              </List>
                            </ul>
                          </div>
                        </div>
                        <button
                          type='button'
                          title='Reset Filters'
                          className='orange_btn reset_filter'
                          onClick={() => setReset()}
                        >
                          <FontAwesomeIcon icon={faUndoAlt} size='lg' />
                        </button>
                        <button type='button' className='roundedButton' onClick={() => onActivateIntro()}>
                          <FontAwesomeIcon icon={faQuestion} />
                        </button>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`filterd-five`}>
            <div className={`d-flex flex-wrap gap-2`}>
              {featuredTag.map(ik => {
                const isActive = filters.tag === ik.tag; // Check if the current tag is active
                return (
                  <button
                    key={ik.tag} // Make sure to add a key
                    className={`btn search_btn ${isActive ? 'search_btn_active' : ''}`} // Apply active class conditionally
                    type='button'
                    onClick={() => handleTagClick(ik.tag)}
                  >
                    {capitalizeFirstLetter(ik.value)}
                  </button>
                );
              })}
              <div className='last_dropdown d-flex align-items-center mx-1'>
                <div className='dropdown'>
                  <button
                    className='btn search_btn dropdown-toggle'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    More
                  </button>
                  <ul className='dropdown-menu'>
                    <List
                      height={500} // Height of the list container
                      itemCount={allTags.length} // Total number of items
                      itemSize={35} // Height of each row
                      width={300} // Width of the list container
                    >
                      {({ index, style }) => (
                        <li key={allTags[index].tag}>
                          <span
                            className='dropdown-item'
                            style={style}
                            onClick={() => handleTagClick(allTags[index].tag)}
                          >
                            {capitalizeFirstLetter(allTags[index].value)}
                          </span>
                        </li>
                      )}
                    </List>
                  </ul>
                </div>
              </div>
              <button type='button' title='Reset Filters' className='orange_btn reset_filter' onClick={() => setReset()}>
                  <FontAwesomeIcon icon={faUndoAlt} size='lg' />
              </button>
              <button type='button' className='roundedButton me-2' onClick={() => onActivateIntro()}>
                <FontAwesomeIcon icon={faQuestion} />
              </button>
            </div>
          </div> */}
            </div>
          </div>
          <div className='container my-5'>
            <div className='plugins_list filtered-second'>
              <div className='plugins_header d-flex align-items-end justify-content-between'>
                <div className='plugins_titles'>
                  <h3 className='plugins_header_title text-white'>{t('new-store.loved_pulgin.title')}</h3>
                  <p className='plugins_header_subtitle'>{t('new-store.loved_pulgin.subtitle')}</p>
                </div>
                <div className='plugins_actions'>
                  <a className='btn search_btn' style={{ color: '#f08921', cursor: 'pointer' }} onClick={() => routeToPremiumPlugin()}>
                  {t('new-store.buttons.browse')}
                  </a>
                </div>
              </div>

              <div className='row'>
                {featuredPlugin.premiumPlugin?.map((plugin: any) => {
                  const isSelectedActive =
                    selectPluginData.find((ik: any) => {
                      return ik.id === plugin.id;
                    }) !== undefined
                      ? true
                      : false;

                  return (
                    <FeaturedPluginCard
                      key={plugin.id}
                      plugin={plugin}
                      setSelectPluginData={setSelectPluginData}
                      selectPluginData={selectPluginData}
                      view={'grid'}
                      isSelectedActive={isSelectedActive}
                      dataType={'plugins'}
                      disablePrivatepack={disablePrivatepack}
                    />
                  );
                })}
              </div>
            </div>

            <div className='plugins_list filtered-third'>
              <div className='plugins_header d-flex align-items-end justify-content-between'>
                <div className='plugins_titles'>
                  <h3 className='plugins_header_title text-white'>{t('new-store.loved_pulgin.list_title')}</h3>
                  <p className='plugins_header_subtitle'>{t('new-store.loved_pulgin.list_sub_title')}</p>
                </div>
                <div className='plugins_actions'>
                  <a className='btn search_btn' style={{ color: '#f08921' }} onClick={() => routeToPlugin()}>
                  {t('new-store.buttons.browse')}
                  </a>
                </div>
              </div>

              <div className='row'>
                {featuredPlugin.plugin?.map((plugin: any) => {
                  const isSelectedActive =
                    selectPluginData.find((ik: any) => {
                      return ik.id === plugin.id;
                    }) !== undefined
                      ? true
                      : false;

                  return (
                    <FeaturedPluginCard
                      key={plugin.id}
                      plugin={plugin}
                      setSelectPluginData={setSelectPluginData}
                      selectPluginData={selectPluginData}
                      view={'grid'}
                      isSelectedActive={isSelectedActive}
                      dataType={'plugins'}
                      disablePrivatepack={disablePrivatepack}
                    />
                  );
                })}
              </div>
            </div>

            <div className='mid_banner'>
              <div className='mid_banner_inner filtered-four'>
                <div className='mid_banner_blocks row'>
                  <div className='mid_banner_block col-md-6 col-12'>
                    <div className='mid_banner_img_views'>
                      <div className='language_logo'>
                        <img
                          src='https://wordpress.com/calypso/images/wp-logo-0496ecb3fdfc3ade5284.svg'
                          alt='wordpress'
                        />
                      </div>
                      <img
                        className='mid_banner_img'
                        src='https://wordpress.com/calypso/images/plugins-video-62a570cbe6f13ed53701.jpg'
                        alt='mid_banner'
                      />
                      <div className='mid_banner_views'>
                      {t('new-store.mid_banner.view')} <span className='mid_banner_views_amount'>1,250</span>
                      </div>
                    </div>
                  </div>
                  <div className='mid_banner_block col-md-6 col-12'>
                    <div className='mid_banner_info'>
                      <h2>
                      {t('new-store.mid_banner.info')}
                      </h2>
                      <button className='orange_btn'>{t('new-store.buttons.started')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='plugins_list filtered-six'>
              <div className='plugins_header d-flex align-items-end justify-content-between'>
                <div className='plugins_titles'>
                  <h3 className='plugins_header_title text-white'>{t('new-store.theme_plugin.title')}</h3>
                  <p className='plugins_header_subtitle'>{t('new-store.theme_plugin.sub_title')}</p>
                </div>
                <div className='plugins_actions'>
                  <a className='btn search_btn' style={{ color: '#f08921' }} onClick={() => routeToTheme()}>
                  {t('new-store.buttons.browse')}
                  </a>
                </div>
              </div>

              <div className='row'>
                {featuredPlugin.theme?.map((plugin: any) => {
                  const isSelectedActive =
                    selectPluginData.find((ik: any) => {
                      return ik.id === plugin.id;
                    }) !== undefined
                      ? true
                      : false;

                  return (
                    <FeaturedPluginCard
                      key={plugin.id}
                      plugin={plugin}
                      setSelectPluginData={setSelectPluginData}
                      selectPluginData={selectPluginData}
                      view={'grid'}
                      isSelectedActive={isSelectedActive}
                      dataType={'theme'}
                      disablePrivatepack={disablePrivatepack}
                    />
                  );
                })}
              </div>
            </div>

            {/* <div className='marketplace_footer'>
            <div className='marketplace_footer_inner'>
              <div className='marketplace_title'>
                <h2>You pick the plugin. We’ll take care of the rest.</h2>
                <button className='orange_btn'>Get Started</button>
              </div>
              <div className='marketplace_blocks row'>
                <div className='marketplace_block col-md-4 col-12'>
                  <div className='marketplace_info'>
                    <h6 className='text-white'>Fully Managed</h6>
                    <p>Premium plugins are fully managed by the team at WordPress.com. No security patches. No update nags. It just works.</p>
                  </div>
                </div>
                <div className='marketplace_block col-md-4 col-12'>
                  <div className='marketplace_info'>
                    <h6 className='text-white'>Thousands of plugins</h6>
                    <p>From WordPress.com premium plugins to thousands more community-authored plugins, we’ve got you covered.</p>
                  </div>
                </div>
                <div className='marketplace_block col-md-4 col-12'>
                  <div className='marketplace_info'>
                    <h6 className='text-white'>Flexible pricing</h6>
                    <p>Pay yearly and save. Or keep it flexible with monthly premium plugin pricing. It’s entirely up to you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </main>
      </>
    </ErrorBoundary>
  );
};

export default NewStore;
