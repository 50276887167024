import React, { useEffect, useState } from 'react'
import '../sass/pages/allnotification.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faClock, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNotificationData } from '../features/getNotificationList';
import moment from 'moment'
import Loader from './Loader'
import ErrorPage from './ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'

const AllNotification = () => {

    const {data:notification, isLoading, isError} = useSelector((state:any) => state.getNotification)
    const [notifyList,setNotifyList] = useState<Array<any>>([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchNotificationData())
      },[dispatch])

    useEffect(() => {
        if(notification !== null){
          setNotifyList(notification.data)
        }
      },[notification])

      if (isLoading) {
        return <Loader />;
      }
    
      if(isError) {
        return <ErrorPage/>
      }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
  <>
    <main className='main'>
        <div className='main_notification'>
            <div className='d-flex justify-content-between all_notification_header'>
                <div className='d-flex'>
                <p className='font20' style={{marginRight:'10px'}}>All Notification</p>
                <FontAwesomeIcon icon={faQuestionCircle} size='lg' color='white'/>
                </div>
                <div className='font20' style={{marginRight:'10px',cursor:'pointer'}}>
                    <p>Mark all as read</p>
                </div>
            </div>
            {notifyList.length > 0 &&
            notifyList.map((ik)=>{
                return(
                    <div className='notification_content'>
                    <div className='notification_bell'>
                        <FontAwesomeIcon icon={faBell} size='sm' color='white'/>
                    </div>
                    <div className='notification_message'>
                    <p> <div dangerouslySetInnerHTML={{__html:ik.message}}/> </p>
                    </div>
                    <div className='notification_time'>
                        <span>
                        <FontAwesomeIcon icon={faClock}/>
                        </span>
                        <p>{moment(ik.created_at).format('dddd, HH:mm:ss, YYYY MMMM')}</p>
                    </div>
                    <div className='notification_read'>
                        <p>Mark as Read</p>
                    </div>
    
                </div>
                )
            })

            }
            {/* <div className='notification_content'>
                <div className='notification_bell'>
                    <FontAwesomeIcon icon={faBell} size='sm' color='white'/>
                </div>
                <div className='notification_message'>
                    <p>Payments have been activated for the publisher account</p>
                </div>
                <div className='notification_time'>
                    <span>
                    <FontAwesomeIcon icon={faClock}/>
                    </span>
                    <p>Today 9:45</p>
                </div>
                <div className='notification_read'>
                    <p>Mark as Read</p>
                </div>

            </div>
            <div className='notification_content'>
                <div className='notification_bell'>
                    <FontAwesomeIcon icon={faBell} size='sm' color='white'/>
                </div>
                <div className='notification_message'>
                    <p>Your auto recharge billing agreement has been canceled as we were uanble to bill you for 5 days in a row </p>
                </div>
                <div className='notification_time'>
                <span>
                    <FontAwesomeIcon icon={faClock}/>
                    </span>
                    <p>Today 9:45</p>
                </div>
                <div className='notification_read'>
                    <p>Mark as Read</p>
                </div>

            </div>
            <div className='notification_content'>
                <div className='notification_bell'>
                    <FontAwesomeIcon icon={faBell} size='sm' color='white'/>
                </div>
                <div className='notification_message'>
                    <p>Payments have been activated for the publisher account</p>
                </div>
                <div className='notification_time'>
                <span>
                    <FontAwesomeIcon icon={faClock}/>
                    </span>
                    <p>Today 9:45</p>
                </div>
                <div className='notification_read'>
                    <p>Mark as Read</p>
                </div>

            </div> */}
        </div>
    </main>
  </>
  </ErrorBoundary>
  )
}

export default AllNotification
