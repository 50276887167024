import { createSlice } from '@reduxjs/toolkit';

export const queue = createSlice({
  name: 'queue',
  initialState: {
    value: {
      open: null,
      all: {},
    },
  },
  reducers: {
    setProjects: (state: any, action) => {
      const payload = action.payload || [];

      for (const project of payload) {
        state.value.all[project.id] = project;
      }
    },
    toggleProject: (state, action) => {
      if (state.value.open === action.payload) {
        state.value.open = null;
      } else {
        state.value.open = action.payload;
      }
    },
    openProject: (state, action) => {
      state.value.open = action.payload;
    },
    addPlugin: (state: any, action) => {
      const project = state.value.open;
      if (!project) return;

      state.value.all[project].plugins.push(action.payload);
    },
    removePlugin: (state: any, action) => {
      const project = state.value.open;
      if (!project) return;

      const plugins = state.value.all[project].plugins;
      for (const i in plugins) {
        if (plugins[i].id === action.payload.id) {
          state.value.all[project].plugins.splice(i, 1);
        }
      }
    },
  },
});

export const { setProjects, toggleProject, openProject, addPlugin, removePlugin } = queue.actions;

export default queue.reducer;
