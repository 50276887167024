/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useUpdateProjectMutation, useProjectQuery } from '../features/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCheckWp } from '../features/hooks';

const EditProject:React.FC = () =>  {
  let { projectid } = useParams();
  const [AddEnvironment] = useUpdateProjectMutation();
  const check = useCheckWp;
  const [formData, setFormData] = useState<any>({
    projectname: '',
    description: '',
    environments: [],
    project_id: projectid,
  });

  const [checkbox, setCheckbox] = useState(false);

  const { data: projectDetails } = useProjectQuery({
    id: projectid,
    data: {},
  });
  const project = projectDetails?.project;
  useEffect(() => {
    setFormData({
      projectname: project?.name,
      slug: project?.slug,
      project_id: project?.id,
      environments: project?.environment,
    });
  }, [project]);

  const handleFileChange = (e: any, env_id: any) => {
    const fileReader = new FileReader();
    const filename = e.target.files[0].name;
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e: any) => {
      // const index = formData.environments?.findIndex((obj: any) => obj.env_id === Number(env_id));
      let data = [...formData.environments];
      const newData = data.map((item: any) => {
        if (item.env_id === Number(env_id)) {
          return {
            ...item,
            composer_json: e.target.result,
            filename: filename,
          };
        } else {
          return item;
        }
      });
      setFormData({
        ...formData,
        environments: newData,
      });
    };
  };

  const handleChange = (event: any, envId?: Number) => {
    const { name, value } = event.target;
    const newData = formData.environments.map((item: any) => {
      if (item.env_id === 'new') {
        return {
          ...item,
          env_id: Number(value),
        };
      } else {
        if (item.env_id == envId) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      }
    });
    if (name === 'environment' || envId) {
      setFormData({
        ...formData,
        environments: newData,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const addNewEnvironment = () => {
    setFormData({
      ...formData,
      environments: [...formData.environments, { env_id: 'new', siteUrl: '', username: '', app_password: '' }],
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      if (project.project_type === 2) {
        const results = await Promise.all(
          formData.environments.map(async (element: any) => {
            return check(element.siteUrl, element.username, element.app_password);
          })
        );

        if (results.some(result => !result)) {
          toast.error(`Please check credentials...`);
          return;
        }
      }

      const envObject = formData.environments.map((item: any) => {
        return {
          ...item,
          composerData: item.composer_json,
        };
      });

      const data1 = {
        name: formData.projectname,
        project_id: projectid,
        project_type: project.project_type,
        environments: envObject,
        slug: formData.slug,
      };
      const res: any = await AddEnvironment(data1);

      if (res?.data.hasOwnProperty('error')) {
        toast.error(`${res?.data.message}`);
      } else {
        toast.success(`${res?.data.message}`);
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  return (
    <main className='main'>
      <div className='container-fluid'>
        <div className='store_listab'>
          <ul className='nav nav-tabs' id='myTab' role='tablist'>
            <li className='nav-item m-0' role='presentation'>
              <button
                className='nav-link active'
                id='team-tab'
                data-bs-toggle='tab'
                data-bs-target='#team'
                type='button'
                role='tab'
                aria-controls='team'
                aria-selected='false'
              >
                Composer Auth
              </button>
            </li>
          </ul>
          <div className='tab-content'>
            <div className='tab-pane active' id='team' role='tabpanel' aria-labelledby='team-tab'>
              <div className='py-3'>
                <div className='row align-items-center'>
                  <div className='col'>
                    <div className='text-end d-flex justify-content-end'>
                      <button className='cblue_btn d-flex align-items-center' onClick={handleSubmit}>
                        <span className='d-inline-block ms-1'>Update project</span>
                      </button>
                      <button className='orange_btn d-flex align-items-center ms-2'>
                        <span className='d-inline-block ms-1'>
                          <svg
                            width='19'
                            height='22'
                            viewBox='0 0 19 22'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9.37922 0.006C9.43122 0.029 9.47922 0.058 9.52822 0.081C9.68766 0.146568 9.82456 0.257127 9.92223 0.399185C10.0199 0.541243 10.0741 0.708658 10.0782 0.881C10.0892 1.168 10.0842 1.455 10.0782 1.742C10.0782 1.863 10.1122 1.914 10.2382 1.942C11.5176 2.15505 12.7016 2.75346 13.6318 3.65727C14.5621 4.56109 15.1944 5.72727 15.4442 7C15.5249 7.4596 15.5654 7.92537 15.5652 8.392C15.5882 9.362 15.5482 10.332 15.6052 11.292C15.6503 12.0819 15.8535 12.8547 16.2027 13.5646C16.5519 14.2746 17.04 14.9072 17.6382 15.425C17.8205 15.5645 17.9731 15.7389 18.0871 15.9381C18.2011 16.1373 18.2742 16.3572 18.3022 16.585C18.3283 16.8064 18.3076 17.0307 18.2415 17.2436C18.1754 17.4564 18.0654 17.653 17.9185 17.8207C17.7717 17.9884 17.5913 18.1234 17.389 18.2169C17.1867 18.3105 16.9671 18.3606 16.7442 18.364C16.6869 18.3669 16.6295 18.3669 16.5722 18.364H1.74422C1.47535 18.3798 1.20666 18.3301 0.961238 18.2191C0.715816 18.1082 0.500994 17.9393 0.335225 17.727C0.0792197 17.3945 -0.0359366 16.9748 0.0145664 16.5583C0.0650694 16.1417 0.277189 15.7617 0.605225 15.5C1.20679 14.9756 1.70413 14.3426 2.07122 13.634C2.53004 12.7368 2.76408 11.7416 2.75322 10.734C2.75322 9.85 2.74222 8.972 2.75922 8.088C2.79652 6.6108 3.34384 5.19201 4.30827 4.07246C5.2727 2.95291 6.59482 2.20158 8.05022 1.946C8.21622 1.917 8.26222 1.854 8.25622 1.699C8.24522 1.527 8.26722 1.355 8.25022 1.182C8.20367 0.933726 8.24889 0.676994 8.37747 0.459567C8.50605 0.24214 8.70924 0.0788233 8.94922 0C9.09222 0.006 9.23622 0.006 9.37922 0.006Z'
                              fill='white'
                            />
                            <path
                              d='M5.85547 19.286H12.4625C12.4175 20.586 10.8265 21.941 9.30347 21.998C8.50011 22.0299 7.71168 21.7744 7.07974 21.2773C6.4478 20.7803 6.01373 20.0743 5.85547 19.286Z'
                              fill='white'
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='select_login_screen addproject'>
                <div className='login_register'>
                  <span>composer.json - upload</span>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={checkbox}
                      id='select_login_screen'
                      name='select_login_screen'
                      onChange={e => setCheckbox(!checkbox)}
                    />
                    <span className='slider round'></span>
                  </label>
                  <span>composer.json - repository</span>
                </div>
              </div>
              {!checkbox ? (
                <div className='py-3 c-upload'>
                  <form className='addproject' onSubmit={handleSubmit}>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Name</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter project name'
                        name='projectname'
                        onChange={handleChange}
                        value={formData.projectname}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Description</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Description'
                        name='description'
                        onChange={handleChange}
                        value={formData.description}
                      />
                    </div>
                    {formData.environments?.map((env: any) => {
                      return (
                        <>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>Environment Type</span>
                            <select
                              className='form-select'
                              id='inputGroupSelect02'
                              name='environment'
                              onChange={handleChange}
                              value={env.env_id}
                            >
                              <option>Select Environment</option>
                              <option
                                value='1'
                                disabled={formData?.environments.find((item: any) => item.env_id === 1)}
                              >
                                Production
                              </option>
                              <option
                                value='2'
                                disabled={formData?.environments.find((item: any) => item.env_id === 2)}
                              >
                                Staging
                              </option>
                              <option
                                value='3'
                                disabled={formData?.environments.find((item: any) => item.env_id === 3)}
                              >
                                QA
                              </option>
                            </select>
                          </div>
                          {Number(project.project_type) === 1 ? (
                            <div className='d-flex mb-3'>
                              <input
                                className='form-control d-none'
                                type='file'
                                id={`formFile_${env.env_id}`}
                                onChange={e => handleFileChange(e, env.env_id)}
                              />
                              <input className='form-control' type='text' value={env.filename} readOnly />
                              <button type='button' className='ms-2 file-btn'>
                                <label htmlFor={`formFile_${env.env_id}`}>Browse</label>
                              </button>
                            </div>
                          ) : (
                            <div className='mb-3'>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>Site URL</span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='https://'
                                  name='siteUrl'
                                  onChange={e => handleChange(e, env.env_id)}
                                  value={env.siteUrl}
                                />
                              </div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>User Name</span>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='username'
                                  name='username'
                                  onChange={e => handleChange(e, env.env_id)}
                                  value={env.username}
                                />
                              </div>
                              <div className='input-group mb-3'>
                                <span className='input-group-text'>Application Password</span>
                                <input
                                  type='password'
                                  className='form-control'
                                  placeholder='password'
                                  name='app_password'
                                  onChange={e => handleChange(e, env.env_id)}
                                  value={env.app_password}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}

                    <div className='d-flex justify-content-end my-5'>
                      <button
                        className='cblue_btn_secondary mx-1'
                        type='button'
                        onClick={addNewEnvironment}
                        disabled={formData?.environments?.length === 3}
                      >
                        Add environment
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className='py-3 c-repo'>
                  <form className='addproject '>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Name</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter project name'
                        value={project?.name}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Description</span>
                      <input type='text' className='form-control' placeholder='Description' />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Environment Type</span>
                      <select className='form-select' id='inputGroupSelect02'>
                        <option>Select Environement</option>
                        <option value='1' disabled={project?.environment.find((item: any) => item.env_id === 1)}>
                          Production
                        </option>
                        <option value='2' disabled={project?.environment.find((item: any) => item.env_id === 2)}>
                          Staging
                        </option>
                        <option value='3' disabled={project?.environment.find((item: any) => item.env_id === 3)}>
                          QA
                        </option>
                      </select>
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>SSH User</span>
                      <input type='text' className='form-control' placeholder='username' />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>SSH Password</span>
                      <input type='password' className='form-control' placeholder='password' />
                    </div>
                    <div className='d-flex justify-content-end my-5'>
                      <button className='cblue_btn_secondary mx-1'>Add environment</button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EditProject;
