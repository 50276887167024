/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Search from './Search';
import Type from './Type';
import Vendors from './Vendors';
import Ratings from './Ratings';
import logo from '../img/logo.jpg';
import userprofile from '../img/userprofile.svg';
import home from '../img/home.svg';
import favorites from '../img/favorites.svg';
import alarms from '../img/alarms.svg';
import settings from '../img/settings.svg';
import pen from '../img/pen.svg';
import clogo from '../img/FusePress_Icon.svg';
import clogotext from '../img/FusePress_Text.svg';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faBars, faCircleArrowUp, faCirclePlus, faCircleUser, faCloudArrowUp, faFile, faHeart, faPlugCircleBolt, faQuestion, faQuestionCircle, faSignOut, faSquarePlus, faStore, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';

import { calculate, clearSearch, setDataType, setFlag, setMaxPrice, setMinPrice, setSearch, setTag, setVendor, toggleRatings } from '../features/storeFilters';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAllPluginQuery, useGetDeployQueueDataQuery,useGetQueueDataByUserQuery, useUserDetailsQuery } from '../features/api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { fetchUserDetails } from '../features/userDetailsSlice';
import { useAppSelector } from '../features/hooks';
import axios from 'axios';
import { useDeleteQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
import { updateTrigger } from '../features/introArray';
import { setDeployNumber, setPluginNumber, setQueueNumber, setWishlistNumber } from '../features/notifyNumbers';
import { faGratipay, faPage4 } from '@fortawesome/free-brands-svg-icons';
import CommonAlert from './CommonAlert';

const Sidenav:React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token');
    // window.location.replace(process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout');
    // navigate('/auth');
    localStorage.setItem("syncLock","0")
    const logoutWindow = window.open(
      process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout',
      '_blank'
    );
    setTimeout(() => {
      if (logoutWindow) {
        logoutWindow.close();
        window.location.reload();
        navigate('/auth');
      }
    }, 2000);
  };
  // const { data: allPluginData } = useAllPluginQuery({ flag: 'ratings' });
  // const { data: vendorData } = useAllPluginQuery({ flag: 'vendor' });
  // const { data: typeData } = useAllPluginQuery({ flag: 'tag' });
  const { data: queueData } = useGetDeployQueueDataQuery({});
  const { data: queueDataByUser } = useGetQueueDataByUserQuery({});
  const  [enableIntro,setEnableIntro] = useState<any>(false)
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  // console.log("queueData",allPluginData)
  const token = localStorage.getItem('token') || '';
  const filters = useAppSelector(state => state.storeFilters.value);
  const [multiSelectArray, setMultiSelectArray] =useState<any>({
    tag:[],
    vendor:[],
    ratings:[]
  })

  const [searchText, setSearchText] = useState<string>('')
  const [pluginType1,setPluginType1] = useState<string>('')
  const [pluginFlag1,setPluginFlag1] = useState<string>('')
  // console.log("filters",filters)
  // useEffect(() => {
  //   dispatch(fetchUserDetails());


  // }, [dispatch]);

  const [collapsed, setCollapsed] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [openLogoutAlert, setOpenLogoutAlert] = useState<boolean>(false)



  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const fetchedWishlist = wishlistString ? JSON.parse(wishlistString) : [];
    setWishlist(fetchedWishlist);
    dispatch(setWishlistNumber(fetchedWishlist.length))
  }, []);
  // console.log('wishlist', wishlist);

  useEffect(() => {
    const plugins = localStorage.getItem('storequeue') ? JSON.parse(localStorage.getItem('storequeue')!):[]
    const wishlist = localStorage.getItem('wishlist') ? JSON.parse(localStorage.getItem('wishlist')!):[]
    if(typeof queueData !=='undefined'&& typeof queueDataByUser !== 'undefined'){
      dispatch(setQueueNumber(queueDataByUser.length))
      dispatch(setDeployNumber(queueData.length))
      dispatch(setPluginNumber(plugins.length))
      dispatch(setWishlistNumber(wishlist.length))
    }

  },[queueData,queueDataByUser])

  const notifyData = useAppSelector((state) =>  state.notifyNumber)
  // console.log("notifyData",notifyData)

  const handleToggle = () => {
    setCollapsed(!collapsed);
    const main:any = document.getElementsByTagName('main');
    const header:any = document.getElementsByClassName('header');
    const search_filterd_menu = document.getElementsByClassName('search_filterd_menu')
    const search_bar_sticky = document.getElementsByClassName('search-bar-sticky')
    if(collapsed){
      header[0].classList.remove('header--slid');
      search_filterd_menu[0]?.classList.remove('search_filter_menu_slide');
    }else{
      if (header.length > 0) {
        header[0].classList.add('header--slid');
        if (search_bar_sticky.length > 0) {
          search_filterd_menu[0]?.classList.add('search_filter_menu_slide');
      }
      }
    }
    if(main.length > 0){
      // if(!window.location.pathname.includes('/store') || window.location.pathname.includes('/queue')){
        if(collapsed){
          main[0].classList.remove('main--slide');
          main[0].style.marginLeft='60px';
        }else{
          main[0].classList.add('main--slide');
          main[0].style.marginLeft='200px';
        }
      // }
    }

    // const computedWidth = window.getComputedStyle(header[0]).width;
    // const currentWidthValue = parseFloat(computedWidth);
    // const collapsedIcon  =document.getElementsByClassName('collapsedIcon');
    // const current = currentWidthValue-140;
    // const previous = currentWidthValue+140;

    // console.log('collapsed',collapsed,computedWidth);
    // if (header.length > 0) {
    //   if (collapsed === false) {
    //     // header[0].style.setProperty('width', `${current}px`, 'important');
    //     // const iconElement = collapsedIcon[0] as HTMLElement;
    //     // iconElement?.style.setProperty('left', '191px', 'important');
    //     console.log("Collapsed is false", {
    //       computedWidth: currentWidthValue,
    //       styleWidth: header[0].style.width,
    //       currentWidth: current,
    //       header,
    //       collapsed
    //     });
    //   } else {
    //     if (previous !== undefined ) {
    //       // header[0].style.setProperty('width', `${previous}px`, 'important');
    //       // const iconElement = collapsedIcon[0] as HTMLElement;
    //     // iconElement.style.setProperty('left', '51px', 'important');
    //       console.log("Collapsed is true", {
    //         styleWidth: header[0].style.width,
    //         previousWidth: previous
    //       });
    //     } else {
    //       console.error("Previous value is undefined or not a number", previous);
    //     }
    //   }
    // }

  };
// useEffect(() => {
//   if(typeof allPluginData !== 'undefined'){
//     const tags = allPluginData.tag.map((ik:any) => {
//       const getData = filters.data.tag.length > 0 ? filters.data.tag.split(',').includes(`${ik.tag}`) ? true : false :false
//       console.log("filters.data.tag.split(',').includes(ik.id)",filters.data.tag.split(',').includes(`${ik.id}`),ik)
//       return {
//         ...ik,
//         checked: getData === true ? true: false
//       }
//     })
//     const vendors = allPluginData.vendor.map((ik:any) => {
//       const getData = filters.data.vendor.length > 0 ? filters.data.vendor.split(',').includes(ik.author) ? true : false :false
//       return {
//         ...ik,
//         checked:getData === true ? true: false
//       }
//     })
//     const ratings = Object.entries(allPluginData.ratings).map(([key, value]: any) => {
//       return {
//         value: key,
//         name: `${key} stars and higher`,
//         length: value,
//         checked:filters.data.ratings.length > 0 ? filters.data.ratings[1] === key ? true:false:false
//       };
//     })

//     // console.log("ratings",ratings)
//     setMultiSelectArray({
//       tag:tags,
//       vendor:vendors,
//       ratings:ratings
//     })
//   }
//   setPluginType1(filters.data.datatype)
// },[allPluginData,filters])

  const handleToggleSidebar = ()=>{
    if(collapsed){
    const header:any = document.getElementsByClassName('header');
    if (header.length > 0) {
      header[0].classList.remove('header--slid');
    }
    // const computedWidth = window.getComputedStyle(header[0]).width;
    // const currentWidthValue = parseFloat(computedWidth);
    // const previous = currentWidthValue+140;
    // header[0].style.setProperty('width', `${previous}px`, 'important');
  }
    setCollapsed(false);
  }

  // const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  // console.log("userdetails",userdetails)
  // // const {data:userdetails}= useUserDetailsQuery({})
  // const loginToWordPress = async (): Promise<string | null> => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('Email', userdetails.email);
  //     formData.append('token', token);
  //     // formData.append("Password",atob(data.password))
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       }
  //     );
  //     const redirectUrl = response.data;
  //     if (redirectUrl === 'Invalid login credentials.') {
  //       const email = userdetails.email;
  //       const atIndex = email.indexOf('@');
  //       if (atIndex !== -1) {
  //         const emailBeforeAt = email.substring(0, atIndex);
  //         axios({
  //           url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
  //           method: 'GET',
  //           headers: {},
  //           params: {
  //             action: 'get_user_data',
  //             fname: userdetails.displayName,
  //             lname: userdetails.displayName,
  //             username: emailBeforeAt,
  //             email: userdetails.email,
  //             role: 'customer',
  //           },
  //           data: {},
  //         })
  //           .then((res1:any) => {
  //             console.log('res1', res1);
  //           })
  //           .catch((err1: any) => {
  //             console.log('err1', err1);
  //           });
  //       }
  //       return redirectUrl;
  //     } else {
  //       return redirectUrl;
  //     }
  //   } catch (error) {
  //     console.error('Error during login:', error);
  //     return null;
  //   }
  // };
  // useEffect(() => {
  //   if (typeof userdetails !== 'undefined') {
  //     const fetchLoginUrl = async () => {
  //       try {
  //         const url = (await loginToWordPress()) || '';
  //         localStorage.setItem('wordpressloginurl', url);
  //         setWordpressLoginUrl(url)

  //       } catch (error) {
  //         setWordpressLoginUrl('')
  //         console.error('Error fetching login URL:', error);
  //       }
  //     };

  //     fetchLoginUrl();
  //   }
  // }, [userdetails]);
  const [wordpressLoginUrl, setWordpressLoginUrl] = useState<string>('');
  // const setPluginType = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   dispatch(setDataType(event.target.value));
  // };
  // const setPluginFlag = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   dispatch(setFlag(event.target.value));
  // };
  // const setReset = ()=>{
  //   window.history.pushState({}, document.title, window.location.pathname);
  //   dispatch(calculate());
  // }



  // function removeQueryString(key:any) {
  //   // Get the current URL
  //   const url = new URL((window as any).location);

  //   // Delete the query string parameter
  //   url.searchParams.delete(key);

  //   // Update the URL without reloading the page
  //   window.history.pushState({}, '', url);
  // }

  const enableIntroFunc = () => {
    // setEnableIntro(true)
    // console.log(window.location.pathname)
    if(window.location.pathname.includes('/store')){
      if(window.location.pathname === "/store"){
        dispatch(updateTrigger({page: 'store', trigger: true}))
      } else if(window.location.pathname.includes('/store/') && window.location.pathname.split('/').length >=3 ){
        dispatch(updateTrigger({page: 'plugins-details', trigger: true}))
      }

    } else if(window.location.pathname.includes('/projects')){
      if(window.location.pathname === "/projects"){
        dispatch(updateTrigger({page: 'projects', trigger: true}))
      } else if(window.location.pathname.includes('/projects/') && window.location.pathname.split('/').length >=3 ){
        dispatch(updateTrigger({page: 'project-details', trigger: true}))
      }
    } else if(window.location.pathname.includes('/queue')){
      dispatch(updateTrigger({page: 'queue', trigger: true}))
    } else if(window.location.pathname.includes('/deploy-pipeline')) {
      dispatch(updateTrigger({page: 'deploy', trigger: true}))
    } else if(window.location.pathname.includes('/featured-plugins')) {
      dispatch(updateTrigger({page: 'featured-plugin', trigger: true}))
    }
    // dispatch(updateTrigger({page: 'store', trigger: true}))
    // updateQueryString("intro","true")
  }

  // console.log('vendorData', vendorData);
  // const redirecttoAccounts = () => {
  //   console.log("hello",wordpressLoginUrl)
  //   if(wordpressLoginUrl.length > 0){
  //     window.open(wordpressLoginUrl,"_blank")
  //   }else {
  //     toast.error("something went wrong")
  //   }
  // }

  // const applyFilter = () => {
  //   const tag = multiSelectArray.tag.filter((ik:any) => {return ik.checked === true}).map((i:any) => {return i.tag}).join(',');
  //   const vendor = multiSelectArray.vendor.filter((ik:any) => {return ik.checked === true}).map((i:any) => {return i.author}).join(',');
  //   const rating = multiSelectArray.ratings.find((ik:any) => {return ik.checked === true})
  //   console.log("rating",rating,multiSelectArray)
  //   const findRating = rating !== undefined ? rating.value : 1
  //   console.log(tag, vendor,findRating,searchText,pluginFlag1,pluginType1)
  //   dispatch(setTag(tag))
  //   dispatch(setSearch(searchText))
  //   dispatch(setVendor(vendor))
  //   dispatch(toggleRatings(findRating))
  //   dispatch(setDataType(pluginType1))
  //   // dispatch(setFlag(pluginFlag1))
  // }


  const {t, i18n} = useTranslation()

  const openLogoutPopup = () => {
    setOpenLogoutAlert(true)
  }
  // console.log("wishlist",wishlist)
  return (
    <aside className='sidebar'>
      <div className={`second-aside ${collapsed ? 'sidebar--Collapse' : ' '}`}>
        <div className='main_logo'>
          <NavLink to='/'>
            <img src={clogo} alt='' />
            <img className='logo_text' src={clogotext} alt='' />
          </NavLink>
        </div>

        <div className='socond_menu' style={{ marginTop: '40px' }}>
          <ul>
            {/* <li >
              <button className='sidenavicon p-0' type='button' onClick={()=>redirecttoAccounts()} style={{background:'#101010', border:'none'}}>

                <FontAwesomeIcon icon={faUser} size='sm' />
                <span className='menuText'>Account</span>

              </button>
            </li> */}

            <li>
              <NavLink
                to='/featured-plugins'
                onClick={handleToggleSidebar}
                className={({ isActive }) => (isActive ? 'active menu-item' : 'menu-item')}
                style={{ textDecoration: 'none' }}
              >
                <span className='sidenavicon' title='Store'>
                  <FontAwesomeIcon icon={faStore} style={{ color: 'gray' }} size='sm' />
                </span>
                <span className='menuText'>Store</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/projects'
                onClick={handleToggleSidebar}
                className={({ isActive }) => (isActive ? 'active menu-item' : 'menu-item')}
                style={{ textDecoration: 'none' }}
              >
                <span className='sidenavicon' title='Plugins'>
                  {/* <FontAwesomeIcon icon={faFile} style={{ color: "gray" }} size='sm' /> */}
                  <svg
                    fill='currentColor'
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 576 512'
                  >
                    <path d='M0 80C0 53.5 21.5 32 48 32l96 0c26.5 0 48 21.5 48 48l0 16 192 0 0-16c0-26.5 21.5-48 48-48l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-16-192 0 0 16c0 1.7-.1 3.4-.3 5L272 288l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-96c0-1.7 .1-3.4 .3-5L144 224l-96 0c-26.5 0-48-21.5-48-48L0 80z'></path>
                  </svg>
                </span>
                <span className='menuText'>Projects</span>
              </NavLink>
            </li>
            {/* <li>
              <Link to='/user-packages' onClick={handleToggleSidebar}>

                <FontAwesomeIcon icon={faPlugCircleBolt} className='sidenavicon'  title='User Package' size='lg'/>
                <span className='menuText'>User Package</span>
              </Link>
            </li> */}
            <li>
              <NavLink
                to={notifyData.deployvalue > 0 ? '/deploy-pipeline' : '#'}
                onClick={handleToggleSidebar}
                className={({ isActive }) =>
                  notifyData.deployvalue > 0 && isActive ? 'active menu-item' : 'menu-item'
                }
                // style={{ textDecoration: 'none', pointerEvents: notifyData.deployvalue > 0 ? 'auto' : 'none' }}
                style={{ textDecoration: 'none'}}
              >
                {notifyData.deployvalue > 0 && (
                  <div
                    className='badge'
                    style={{
                      position: 'absolute',
                      backgroundColor: notifyData.deployvalue === 0 ? 'grey' : '#F08921',
                      height: '18px',
                      color: 'white',
                      width: '18px',
                      top: '-8px',
                      right: '-8px',
                      borderRadius: '50%',
                      padding: '0px',
                      paddingTop: '2px',
                    }}
                  >
                    {notifyData.deployvalue}
                  </div>
                )}
                <FontAwesomeIcon icon={faCircleArrowUp} className='sidenavicon' title='Deploy Pipeline' size='lg' />
                <span className='menuText'>Deploy Pipeline</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to='/queue'
                onClick={handleToggleSidebar}
                className={({ isActive }) =>
                  notifyData.plugincount > 0 && isActive ? 'active menu-item' : 'menu-item'
                }
                // style={{ textDecoration: 'none', pointerEvents: notifyData.plugincount > 0 ? 'auto' : 'none' }}
                style={{ textDecoration: 'none'}}
              >
                {notifyData.plugincount > 0 && (
                  <div
                    className='badge'
                    style={{
                      position: 'absolute',
                      backgroundColor: notifyData.plugincount === 0 ? 'grey' : '#F08921',
                      height: '18px',
                      color: 'white',
                      width: '18px',
                      top: '-8px',
                      right: '-11px',
                      borderRadius: '50%',
                      padding: '0px',
                      paddingTop: '2px',
                    }}
                  >
                    {notifyData.plugincount}
                  </div>
                )}
                <FontAwesomeIcon icon={faCirclePlus} className='sidenavicon' title='Queue' size='lg' />
                <span className='menuText'>Queue</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to={notifyData.wishlistvalue > 0 ? '/wishlist' : '#'}
                onClick={handleToggleSidebar}
                className={({ isActive }) =>
                  notifyData.wishlistvalue > 0 && isActive ? 'active menu-item' : 'menu-item'
                }
                // style={{ textDecoration: 'none', pointerEvents: notifyData.wishlistvalue > 0 ? 'auto' : 'none' }}
                style={{ textDecoration: 'none'}}
              >
                {notifyData.wishlistvalue > 0 && (
                  <div
                    className='badge'
                    style={{
                      position: 'absolute',
                      backgroundColor:'#F08921',
                      height: '18px',
                      color: 'white',
                      width: '18px',
                      top: '-8px',
                      right: '-11px',
                      borderRadius: '50%',
                      padding: '0px',
                      paddingTop: '2px',
                    }}
                  >
                    {notifyData.wishlistvalue}
                  </div>
                )}
                <FontAwesomeIcon icon={faGratipay} className='sidenavicon' title='Wishlist' />
                <span className='menuText'>Wishlist</span>
              </NavLink>
            </li>

            <li>
              <a onClick={() => enableIntroFunc()} className='menu-item' style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className='sidenavicon'
                  title='Help'
                  size='lg'
                />
                <span className='menuText' style={{ cursor: 'pointer' }}>
                  Help
                </span>
              </a>
            </li>

            <li>
              <a
                // to='/'
                onClick={openLogoutPopup}
                style={{cursor:"pointer"}}
                className='menu-item'
                // className={({ isActive }) => (isActive ? 'active menu-item' : 'menu-item')}
              >
                <FontAwesomeIcon icon={faSignOut} className='sidenavicon' title='Log Out' size='lg' />
                <span className='menuText'>Log Out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`${collapsed ? 'collapseToggleIconOpen' : 'collapseToggleIcon'}`}
        onClick={handleToggle}
        style={{ marginTop: '3px', position: 'fixed', top: '250px', zIndex: '9999999', cursor: 'pointer' }}
      >
        <FontAwesomeIcon className='sidenavicon' icon={collapsed ? faAnglesLeft : faAnglesRight} color='white' />
      </div>
      <CommonAlert
            onHide={setOpenLogoutAlert}
            show={openLogoutAlert}
            message={'Are you sure you want to Logout current session'}
            clickFunc={() => logout()}
            // onClickReject={() => saveAndPerformSshAction(false,"ssh")}
          />
      {location.pathname === '/store' && (
        // <div className={`first-aside  ${collapsed ? 'first--Collapse' : ' '}`}>
        //   {/* <div className='logo px-3 text-center'>
        //     <NavLink to='/' onClick={handleToggleSidebar}>
        //       <img src={logo} alt='' />
        //     </NavLink>
        //   </div> */}
        //   <div className="first-aside-inner">
        //   <Search searchText={searchText} setSearchText={setSearchText} />
        //   <div className='accordion accordion-flush' id='sideaccordion'>
        //     {/* <div className="accordion-item">
        //                 <h2 className="accordion-header" id="flush-headingCategory">
        //                     <button className="accordion-button px-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCategory" aria-expanded="false" aria-controls="flush-collapseCategory">
        //                         Category
        //                     </button>
        //                 </h2>
        //                 <div id="flush-collapseCategory" className="accordion-collapse px-4 collapse" aria-labelledby="flush-headingCategory" data-bs-parent="#sideaccordion">
        //                     <div className="accordion-body">
        //                         <ul>
        //                             <li><Link to="#"><span>E-Commerce</span><span>29</span></Link></li>
        //                             <li><Link to="#"><span>SEO</span><span>11</span></Link></li>
        //                             <li><Link to="#"><span>Add ones</span><span>205</span></Link></li>
        //                             <li><Link to="#"><span>Social Networking</span><span>866</span></Link></li>
        //                             <li><Link to="#"><span>Membership</span><span>2180</span></Link></li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //             </div> */}
        //     <div className='accordion-item'>
        //       <h2 className='accordion-header' id='flush-headingTags'>
        //         <button
        //           className='accordion-button px-4 collapsed'
        //           type='button'
        //           data-bs-toggle='collapse'
        //           data-bs-target='#flush-collapseTags'
        //           aria-expanded='false'
        //           aria-controls='flush-collapseTags'
        //         >
        //            {t('store-list.sidebar.Tags')}
        //         </button>
        //       </h2>
        //       <div
        //         id='flush-collapseTags'
        //         className='accordion-collapse px-4 collapse'
        //         aria-labelledby='flush-headingTags'
        //         data-bs-parent='#sideaccordion'
        //       >
        //         {typeof allPluginData !== 'undefined' &&
        //         <div className='accordion-body'>
        //           <Type typeData={multiSelectArray} multiSelectArray={multiSelectArray} setMultiSelectArray={setMultiSelectArray} />
        //         </div>}
        //       </div>
        //     </div>
        //     <div className='accordion-item'>
        //       <h2 className='accordion-header' id='flush-headingVendor'>
        //         <button
        //           className='accordion-button px-4 collapsed'
        //           type='button'
        //           data-bs-toggle='collapse'
        //           data-bs-target='#flush-collapseVendor'
        //           aria-expanded='false'
        //           aria-controls='flush-collapseVendor'
        //         >
        //            {t('store-list.sidebar.Vendor')}
        //         </button>
        //       </h2>
        //       <div
        //         id='flush-collapseVendor'
        //         className='accordion-collapse px-4 collapse'
        //         aria-labelledby='flush-headingVendor'
        //         data-bs-parent='#sideaccordion'
        //       >
        //         {typeof allPluginData !== 'undefined' &&
        //         <div className='accordion-body'>
        //           <Vendors vendorData={multiSelectArray} multiSelectArray={multiSelectArray} setMultiSelectArray={setMultiSelectArray} />
        //         </div>}
        //       </div>
        //     </div>
        //     {/* <div className="accordion-item">
        //                 <h2 className="accordion-header" id="flush-headingPrice">
        //                     <button className="accordion-button px-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePrice" aria-expanded="false" aria-controls="flush-collapsePrice">
        //                         Price
        //                     </button>
        //                 </h2>
        //                 <div id="flush-collapsePrice" className="accordion-collapse px-4 collapse" aria-labelledby="flush-headingPrice" data-bs-parent="#sideaccordion">
        //                     <div className="accordion-body">
        //                         <div className="pricebox">

        //                             <div className="mintext">
        //                                 <span>$</span>
        //                                 <input name="minPrice" type="text" onChange={(e)=>{dispatch(setMinPrice(e.target.value))}}/>
        //                             </div>
        //                             <div className="price_divider">-</div>
        //                             <div className="maxtext">
        //                                 <span>$</span>
        //                                 <input name="maxPrice" type="text" onChange={(e)=>{dispatch(setMaxPrice(e.target.value))}}/>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div> */}
        //     <div className='accordion-item'>
        //       <h2 className='accordion-header' id='flush-headingRatings'>
        //         <button
        //           className='accordion-button px-4 collapsed'
        //           type='button'
        //           data-bs-toggle='collapse'
        //           data-bs-target='#flush-collapseRatings'
        //           aria-expanded='false'
        //           aria-controls='flush-collapseRatings'
        //         >
        //            {t('store-list.sidebar.Ratings')}
        //         </button>
        //       </h2>
        //       <div
        //         id='flush-collapseRatings'
        //         className='accordion-collapse px-4 collapse'
        //         aria-labelledby='flush-headingRatings'
        //         data-bs-parent='#sideaccordion'
        //       >
        //         {typeof allPluginData !== 'undefined' &&
        //         <div className='accordion-body'>
        //           <Ratings ratingData={multiSelectArray} multiSelectArray={multiSelectArray} setMultiSelectArray={setMultiSelectArray} />
        //         </div>}
        //       </div>
        //     </div>
        //     <div className='accordion-item'>
        //       <h2 className='accordion-header' id='flush-headingPlugins'>
        //         <button
        //           className='accordion-button px-4 collapsed'
        //           type='button'
        //           data-bs-toggle='collapse'
        //           data-bs-target='#flush-collapsePlugins'
        //           aria-expanded='false'
        //           aria-controls='flush-collapsePlugins'
        //         >
        //            {t('store-list.sidebar.Plugin')}
        //         </button>
        //       </h2>
        //       <div
        //         id='flush-collapsePlugins'
        //         className='accordion-collapse px-4 collapse'
        //         aria-labelledby='flush-headingPlugins'
        //         data-bs-parent='#sideaccordion'
        //       >
        //         <div className='accordion-body'>
        //           <div>
        //             <div className='form-check'>
        //               <input
        //                 className='form-check-input'
        //                 type='radio'
        //                 // id="checkbox"
        //                 name='plugintype'
        //                 value='private'
        //                 checked={pluginType1 === 'private'}
        //                 // onChange={e => setPluginType(e)}
        //                 onChange={e => setPluginType1(e.target.value)}
        //               />
        //               <label className='form-check-label text-secondary' htmlFor='checkbox'>
        //               {t('store-list.sidebar.Private-Plugin')}
        //               </label>
        //             </div>
        //             <div className='form-check'>
        //               <input
        //                 className='form-check-input'
        //                 type='radio'
        //                 // id="checkbox"
        //                 name='plugintype'
        //                 value='public'
        //                 checked={pluginType1 === 'public'}
        //                 // onChange={e => setPluginType(e)}
        //                 onChange={e => setPluginType1(e.target.value)}
        //               />
        //               <label className='form-check-label text-secondary' htmlFor='checkbox'>
        //               {t('store-list.sidebar.Public-Plugin')}
        //               </label>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     {/* <div className='accordion-item'>
        //       <h2 className='accordion-header' id='flush-headingPlugins1'>
        //         <button
        //           className='accordion-button px-4 collapsed'
        //           type='button'
        //           data-bs-toggle='collapse'
        //           data-bs-target='#flush-collapsePlugins1'
        //           aria-expanded='false'
        //           aria-controls='flush-collapsePlugins1'
        //         >
        //            {t('store-list.sidebar.Type')}
        //         </button>
        //       </h2>
        //       <div
        //         id='flush-collapsePlugins1'
        //         className='accordion-collapse px-4 collapse'
        //         aria-labelledby='flush-headingPlugins1'
        //         data-bs-parent='#sideaccordion'
        //       >
        //         <div className='accordion-body'>
        //           <div>
        //             <div className='form-check'>
        //               <input
        //                 className='form-check-input'
        //                 type='radio'
        //                 name='plugintype1'
        //                 value='plugins'
        //                 onChange={e => setPluginFlag1(e.target.value)}
        //               />
        //               <label className='form-check-label text-secondary' htmlFor='checkbox'>
        //               {t('store-list.sidebar.Plugins')}
        //               </label>
        //             </div>
        //             <div className='form-check'>
        //               <input
        //                 className='form-check-input'
        //                 type='radio'
        //                 name='plugintype1'
        //                 value='theme'
        //                 onChange={e => setPluginFlag1(e.target.value)}
        //               />
        //               <label className='form-check-label text-secondary' htmlFor='checkbox'>
        //               {t('store-list.sidebar.Theme')}
        //               </label>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div> */}
        //     <div style={{marginTop:'20px', marginLeft:'0px', display:'flex' , justifyContent:'space-around'}}>
        //           <button type='button' className='orange_btn' onClick={setReset}>
        //             Reset Filter
        //           </button>
        //           <button type='button' className='orange_btn' onClick={() => applyFilter()}>
        //             Apply Filter
        //           </button>
        //         </div>
        //   </div>
        //   </div>
        // </div>
        <></>
      )}
    </aside>
  );
}
export default Sidenav;
