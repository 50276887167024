import { faCircleInfo} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function DeployHistory() {
  return (
    <main className='main'>
      <div className='main_queue_header'>
        {/* {/ <button className='cblue_btn'>Back</button> /} */}
        {/* <button className='orange_btn'>
          <FontAwesomeIcon icon={faGear} />
          Connection Settings</button> */}
      </div>
      <div className='project_queue_table' style={{ marginLeft: '0px' , maxWidth:'100%'}}>
        <div className='table-responsive'>
          <table className='table queue_table'>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Current server version</th>
                <th>New environment version</th>
                <th>Action</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td>WooCommerce</td>
                <td>v 5.5.8</td>
                <td>v 5.6.2</td>
                <td>updated</td>
                <td>unchanged</td>
              </tr>

              <tr>
                <td>Yoast SEO</td>
                <td>v 5.5.8</td>
                <td>n/a</td>
                <td>removed</td>
                <td>unchanged</td>
              </tr>

              <tr>
                <td>Elementor Website Builder</td>
                <td>v 5.5.8</td>
                <td>n/a</td>
                <td>downgraded</td>
                <td>activated</td>
              </tr>



            </tbody>
          </table>
        </div>
      </div>
      <div className='message_container' style={{ marginLeft: '0px' , maxWidth:'100%'}}>
        <div className='inner_message_container'>
          <FontAwesomeIcon icon={faCircleInfo} color='#F28A03' />
          <span>You need to set up a Connection before being able to deploy. Your settings will be saved for future deploys.</span>
        </div>
      </div>
      <div className='queue_deploy_btn'>
        <button className='orange_btn' disabled style={{ height: '35px', padding: '0px 42px' }}>Deploy</button>
      </div>

    </main>
  )
}

export default DeployHistory
