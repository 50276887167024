// /* eslint-disable @typescript-eslint/no-unused-vars */
// import React, { useEffect } from 'react';
// import { Outlet, Navigate, useLocation } from 'react-router-dom';
// import { useUserDetailsQuery } from '../features/api';
// import Loader from '../components/Loader';
// import { fetchUserDetails } from '../features/userDetailsSlice';
// import { useDispatch } from 'react-redux';
// import { useAppSelector } from '../features/hooks';
// import axios from 'axios';
// // import SubscriptionModal from '../components/SubscriptionModel';

// const PrivateRoutes = async() => {
//   const dispatch =useDispatch();
//   const auth = { token: localStorage.getItem('token') ? true : false };
//   const location = useLocation();
//   // useEffect(() => {
//   //   if(auth.token){
//   //     dispatch(fetchUserDetails());
//   //   }



//   // }, [dispatch,window.location.pathname,auth.token]);
//   // const { data: userdetails, isLoading: accountLoad,isError } = useUserDetailsQuery({});
//   // const { data:userdetails,status } = useAppSelector((state:any) => state.userDetails);
//   // console.log("userdetails",userdetails,accountLoad,isError)
//   console.log("status",status)
//   function getData () {
//     return new Promise((resolve,reject) => {
//       try {
//          axios({
//           url: `${process.env.REACT_APP_API_URL}/api/getUserDetails`,
//           method: 'POST',
//           data: {},
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${localStorage.getItem('token')}`,
//           },
//         }).then((res:any) => {
//           if(Object.prototype.hasOwnProperty.call(res.data,'error')){
//             reject(false)
//           } else {
//             resolve(true)
//           }
//         })
//         // return response.data;
//       } catch (error) {
//         reject(false)
//         // const state = getState();
//         // console.error('Error fetching user details:', error);
//         // // console.error('Current state:', state);
//         // throw error;
//       }
//     })
//   }

//   // if(!auth.token){
//   //   return <Navigate to='/auth' />
//   // }

//   // // if(status === 'pending'){
//   // //   return <Loader/>
//   // // }
//   // if(userdetails && Object.prototype.hasOwnProperty.call(userdetails,'error')){
//   //   return <Navigate to='/auth' />
//   // }
//   if(await getData()){
//     return  <Outlet/>
//   } else {
//     return <Navigate to='/auth'/>
//   }

// //
//   // const excludeModalRoutes = ['/reset-password', '/set-password', '/forgot-password','/auth','/authOtp'];

//   // return auth.token ? (
//   //   <>
//   //     {/* {!excludeModalRoutes.includes(location.pathname) && <SubscriptionModal />} */}
//   //     <Outlet />
//   //   </>
//   // ) : (
//   //   <Navigate to='/auth' />
//   // );
// };

// export default PrivateRoutes;
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import axios from 'axios';
import useOnlineStatus from './useOnlineHooks';
import useNetworkStatus from './useOnlineHooks';
// import SubscriptionModal from '../components/SubscriptionModel';

const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const auth = { token: localStorage.getItem('token') ? true : false };
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/api/getUserDetails`,
          method: 'POST',
          data: {},
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (Object.prototype.hasOwnProperty.call(response.data, 'error')) {
          setIsAuthorized(false);
        } else {
          setIsAuthorized(true);
        }
      } catch (error) {
        setIsAuthorized(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (auth.token) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [location.pathname]);
  // const {isOnline, isRealOnline} = useNetworkStatus()
  // if(isOnline === false || isRealOnline === false){
  //   return (
  //     <main className='main'>
  //       <div className='text-white'>
  //         No Internet connection Please refresh the page
  //       </div>
  //     </main>
  //   )
  // }

  if (!auth.token) {
    return <Navigate to='/auth' />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthorized) {
    return <Navigate to='/auth' />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
