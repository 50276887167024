import { faCircleMinus, faCirclePlus, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { SetStateAction, useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { toast } from 'react-toastify'
import { setPluginNumber, setWishlistNumber } from '../features/notifyNumbers'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../features/hooks'
import img from '../img/store-thumbnail.svg';

interface FeaturedPluginCardInterface {
  key:any
  plugin:any
  setSelectPluginData:React.Dispatch<SetStateAction<Array<any>>>
  selectPluginData:Array<any>
  view:string
  isSelectedActive:boolean;
  dataType:'plugins'|'theme'
  disablePrivatepack:boolean
}

const FeaturedPluginCard:React.FC<FeaturedPluginCardInterface> = ({key,plugin,setSelectPluginData,selectPluginData,view,isSelectedActive,dataType,disablePrivatepack}) =>  {

  const [wishlist1, setWishlist] = useState([]);
  // const [isPluginInWishlist,setIsPluginInWishlist] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  // const { slug } = useParams();
  // const { data: pluginDetails, error, isLoading, isError } = useGetDataByPluginQuery({ plugin_id: slug });
  const queryString = new URLSearchParams(window.location.search);
  let selectedPlugin = useSelector((state: any) => state.userplugins.value).plugins;
  const [src,setSrc] =useState(`https://ps.w.org/${plugin.slug}/assets/icon-256x256.png`)
  const filters = useAppSelector(state => state.storeFilters.value);

  // const favorite = false ? favorite_select : favorite_unselect;
  const {t, i18n} = useTranslation()
  const navigate =useNavigate()
  const dispatch =useDispatch()

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    setWishlist(wishlist)
    if (Array.isArray(wishlist)) {
      const isAlreadyInWishlist = wishlist.some((plugins:any) =>
      plugins.id === plugin.id && plugins.dataType === filters.data.flag
      );
      if (isAlreadyInWishlist) {
        // setIsPluginInWishlist(true);
        setIsFavorite(true)
      }
    }
  },[plugin, view]);
  // console.log("selectPluginData",selectPluginData)

  useEffect(() => {
    dispatch(setPluginNumber(selectPluginData.length))
  },[selectPluginData])

  // const addremove=(data: Iitem, e: any) => {
  //   data = Object.assign({selectedVersion: data.versions[0]?.id}, data)
  //   let pluginData=[...selectedPlugin]
  //   let index=pluginData.findIndex(x => x.id === data.id);
  //   if(index > -1){
  //     pluginData.splice(index, 1);
  //   }else{
  //     pluginData.push(data)
  //   }
  //   try {
  //     const result = syncUserSession({sessonData:JSON.stringify(pluginData)})
  //     result.then((r:any)=>{
  //       switch (e.target.innerHTML) {
  //         case 'Select':
  //           toast.success(`${data.name} added to current queue`);
  //           break;

  //         case 'Remove':
  //           toast.success(`${data.name} removed from current queue`);
  //           break;

  //         default:
  //           break;
  //     }
  //   })
  //   } catch (error) {
  //     toast.error(`${error}`)
  //   }
  // }

  useEffect(() => {

      dispatch(setWishlistNumber(wishlist1.length))


  },[wishlist1])



  const onAddPlugin = (plugin: any, event: any): void => {
    // console.log('plugin', plugin, event, selectPluginData);
    const a = selectPluginData;
    const filterdata = selectPluginData.find((i: any) => {
      return i.id === plugin.id;
    });
    if (filterdata !== undefined) {
      const ab = a.filter((i: any) => {
        return i.id !== plugin.id;
      });
      toast.error('Plugin removed from queue')
      localStorage.setItem('storequeue', JSON.stringify(ab!));
      setSelectPluginData(ab);
    } else {
      const abc = [...a, plugin];
      toast.success('Plugin added to queue')
      localStorage.setItem('storequeue', JSON.stringify(abc!));
      setSelectPluginData(abc);
    }
  };

const addToWishlist = () => {
    // if(!isFavorite){

    // }else{
    //   setIsFavorite(true)
    // }
    // console.log("pluginDetails",  filters);
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];

    const isAlreadyInWishlist = wishlist.some((plugins:any) => plugins.id === plugin.id && plugins.dataType===dataType);
    if (!isAlreadyInWishlist) {
      const newObj = {...plugin, versions:plugin.versions, dataType:dataType}
      wishlist.push(newObj);
      setWishlist(wishlist)
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      setIsFavorite(true)
      toast.success('Plugin Added to wishlist');
      // console.log('Added to wishlist:', plugin);

    } else {
      // console.log("wishlist",wishlist)
      const filtered = wishlist.filter((ik:any) => {return !(ik.id === plugin.id && ik.dataType===dataType)})
      setWishlist(filtered)
      setIsFavorite(false)
      localStorage.setItem('wishlist', JSON.stringify(filtered));
      toast.error('removed from wish list');
    }
    }

  const handleImageError = () => {
     setSrc(img)
  }
  const isPremium = dataType !== 'theme' ? plugin?.versions.some((obj:any) => {return obj.fusepress_path !== null})?'Premium':'Free':"Free"
  const renderRating = () => {
    if (plugin.type === 'private' && plugin.ratings !== undefined) {
      return (
        <div className='tooltip-container'>
          <div className='tooltip'>{t('store-list.plugin.Private')}</div>
        </div>
        //   {/* <div className="price">${plugin.rating}</div> */}
        //   <Rating tooltipDefaultText="Private" showTooltip={true} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" allowHover={false}/>
      );
    } else {
      return (
        <div aria-disabled={true}>
          {/* <div className="price">${plugin.rating}</div> */}
          <Rating
            initialValue={plugin.rating / 20}
            allowFraction={true}
            fillColor='#F28A03'
            size={20}
            className='float-start'
            emptyColor='#888888'
            allowHover={false}
            disableFillHover={true}
            readonly
          />
        </div>
      );
    }
  };

  const NavigateTo = (plugin:any) =>{
    navigate(`/store/${plugin.slug}`)
  }




  return (
    <div className="col-xxl-4 col-xl-4 col-md-6 col-sm-12 mt-2" onClick={() => NavigateTo(plugin)}>
    <span  className="plug_grid" style={{cursor:'pointer'}}>
      <div className="plugins_info">
        <div className="plugins_item_header">
          <div className="plugin_icon">
            <img className='img-fluid' src={src} onError={() => handleImageError()} alt="plugin_icon" />
          </div>
          <div className="plugins_title_author">
            <div className="plugins_item_title" title={plugin.name ? plugin.name : plugin.slug}><h6>{plugin.name ? plugin.name.substr(0,20) +'...' : plugin.slug}</h6></div>
            <div className="plugins_item_author"><p>by <span className="plugins_item_author_name">{plugin.wp_author}</span></p></div>
           <div className={`product_like ${isFavorite ? 'favorite' : ''}`} style={{width:'35px',height:'35px'}}  onClick={(e) =>{
        e.stopPropagation()
        addToWishlist()}}>
              <FontAwesomeIcon icon={faHeart}  style={{color:'white'}} size='lg' />
         </div>
         <div className="plugins_item_ratings">
         {renderRating()}
            {/* <svg className="star-svg" stroke="currentColor" fill="#f08921" stroke-width="0" viewBox="0 0 24 24" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>
            <span className="plugins_item_rating_value"><p>4.6</p></span> */}
          </div>
          </div>
        </div>
        <div className="plugins_item_description">
          {/* <p style={{width:'35%'}} dangerouslySetInnerHTML={{__html:plugin.description}}></p> */}
          <p>{plugin.short_description}</p>
        </div>
        <div style={{height:'30px'}}>
          {plugin.last_updated !== null && dataType !== 'theme' ? <span className='text-white'>Last Updated:<span style={{color:"#f08921"}}>&nbsp;{plugin.last_updated}</span></span> : null}
        </div>
      </div>
      <div className="plugins_footer">
        <div className="plugins_item_pricing"><p>{isPremium}</p></div>
        <div className="plugins_item_additional_info"></div>
          {dataType === 'plugins' && <div className='plugin_select_btn' onClick={e => {
                e.stopPropagation()
                onAddPlugin(plugin, e)
              }}>
                {isPremium === 'Premium' ? disablePrivatepack === false ? <FontAwesomeIcon icon={isSelectedActive ?faCircleMinus: faCirclePlus} size='2xl' color={isSelectedActive ? "#fd2525" :'#f08921'}/> :null:<FontAwesomeIcon icon={isSelectedActive ?faCircleMinus: faCirclePlus} size='2xl' color={isSelectedActive ? "#fd2525" :'#f08921'}/> }
            {/* <FontAwesomeIcon icon={isSelectedActive ?faCircleMinus: faCirclePlus} size='2xl' color={isSelectedActive ?"#fd2525" :'#f08921'}/> */}
            </div>}

      </div>
    </span>
  </div>
  )
}

export default FeaturedPluginCard
