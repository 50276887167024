import React, { useMemo } from 'react';

type PaginatedListProps = {
  children?: React.ReactNode;
  page: number;
  pages: number;
  setPage: (value: number) => void;
};

function calculatePagination(page: number, pages: number) {
  let before: number[] | null = null;
  let current: number[] = [];
  let after: number[] | null = null;

  if (pages <= 5) {
    current = [...Array(pages).keys()].map(i => i + 1);
  } else if (page <= 3) {
    current = [1, 2, 3, 4, 5];
    after = [pages];
  } else if (page >= pages - 2) {
    before = [1];
    current = [pages - 4, pages - 3, pages - 2, pages - 1, pages];
  } else {
    before = [1];
    current = [page - 2, page - 1, page, page + 1, page + 2];
    after = [pages];
  }

  if (before && current[0] > before[before.length - 1] + 1) {
    before.push(-1);
  }
  if (after && current[current.length - 1] < after[0] - 1) {
    after.unshift(-1);
  }

  return [...(before || []), ...current, ...(after || [])];
}
const  PaginatedList:React.FC<PaginatedListProps> = ({ children, page, setPage, pages }: PaginatedListProps) =>  {
  const pagination = useMemo(() => calculatePagination(page, pages), [page, pages]);

  return (
    <>
      {children}
      <div className='paginate'>
        <button onClick={() => setPage(1)} disabled={page === 1}>
          &lt;&lt;
        </button>
        <button onClick={() => setPage(page - 1)} disabled={page === 1}>
          &lt;
        </button>
        {pagination.map((pageNum, index) => {
          if (pageNum === -1) {
            return (
              <p className='paginate-dots' key={index}>
                ...
              </p>
            );
          } else if (pageNum === page) {
            return (
              <p className='paginate-current' key={index}>
                {pageNum}
              </p>
            );
          } else {
            return (
              <button key={index} onClick={() => setPage(pageNum)}>
                {pageNum}
              </button>
            );
          }
        })}
        <button onClick={() => setPage(page + 1)} disabled={page === pages}>
          &gt;
        </button>
        <button onClick={() => setPage(pages)} disabled={page === pages}>
          &gt;&gt;
        </button>
      </div>
    </>
  );
}
export default PaginatedList;
