import React, { useState } from 'react';
import { useLoginMutation } from '../features/api';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';

const Login:React.FC = () => {
  const [icon, setIcon] = useState(faEyeSlash);
  const [passwordShow, setPasswordShow] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [AuthLogin] = useLoginMutation();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const togglePassword = (e: any) => {
    setPasswordShow(!passwordShow);
    if (passwordShow) {
      setIcon(faEyeSlash);
    } else {
      setIcon(faEye);
    }
  };
  const [error, setError] = useState<any>({});

  const validateForm = (): any => {
    const newError: any = {};

    if (!formData.email) {
      newError.email = 'email is required';
    }
    if (!formData.password) {
      newError.password = 'password is required';
    }

    setError(newError);

    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      setDisableButton(true);
      let data = {
        email: formData.email,
        password: formData.password,
      };
      AuthLogin(data)
        .then((res: any) => {
          if (Object.keys(res).length > 0) {
            setDisableButton(false);
            if (res.data.hasOwnProperty('error')) {
              // toast.error(`${res.data.message}`);
              toast.error(`${res.data.message}`, { theme: "colored", autoClose: 1000 });
            } else {
              const data = {
                email: btoa(formData.email),
                password: btoa(formData.password),
              };
              localStorage.setItem('uniqueCode', res.data.user.otp);
              localStorage.setItem('wordpresslogin', JSON.stringify(data));
              if(res.data.user.otp_preference === true ){
                navigate('/authOtp');
              } else {
                localStorage.setItem('token', res.data.user.token)
                setTimeout(() => {
                  navigate('/');
                },1000)
              }
            }
          }
        })
        .catch((err: any) => {
          // toast.error(`${err}`);
          toast.error(`${err}`, { theme: "colored", autoClose: 1000 });
        });
    } else {
      console.log('form validation failed');
    }
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  return (
    <div className='fp_custom_login'>
      <form onSubmit={handleSubmit}>
        <div className='input-container'>
          <FontAwesomeIcon icon={faEnvelope} className='fa fa-envelope icon' />
          <input
            type='email'
            className='input-field'
            placeholder='Email Id'
            name='email'
            onChange={handleChange}
            value={formData.email}
          />
          {error.email && <span style={{ color: 'red', fontSize: '14px' }}>{error.email}</span>}
        </div>
        <div className='input-container'>
          <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
          <input
            type={passwordShow ? 'text' : 'password'}
            className='input-field'
            placeholder='Password'
            name='password'
            onChange={handleChange}
            value={formData.password}
          />
          <FontAwesomeIcon icon={icon} className='icon icon_sec' onClick={togglePassword} />
          {error.password && <span style={{ color: 'red', fontSize: '14px' }}>{error.password}</span>}
        </div>
        <div className='forget_password'>
          <Link to='/forgot-password' className='fp_forgot_pass'>
            Forgot Password?
          </Link>
        </div>
        <button
          className='orange_btn w-100 mt-3'
          disabled={disableButton}
          style={{ cursor: disableButton ? 'not-allowed' : 'pointer' }}
        >
          {disableButton ? 'Loading...' : 'Login'}
        </button>
        <p className='log_reg_with'>OR Login with</p>
      </form>
    </div>
  );
}

export default Login;
