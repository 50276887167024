import React, { useEffect, useState } from 'react';
import UserPackagesCard from './UserPackagesCard'; // Assuming this is your card component
import { useGetUserWisePluginQuery } from '../features/api';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';
import ErrorPage from './ErrorPage';

const UserPackages: React.FC = () => {
  const [userPlugin, setUserPlugin] = useState<Array<any>>([]);

  const { data: userData, isLoading, isError } = useGetUserWisePluginQuery({});
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof userData !== 'undefined') {
      setUserPlugin(userData);
    }
  }, [userData]);

  const onNavigatetoAddPackage = () => {
    navigate('/add-user-package');
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorPage />
  }

  return (
    <main className='main main-side-sort overflow-hidden'>

      <div className='store_list_search'>
        <div className='store_list'>
          <ul className='nav nav-tabs projects-first' id='myTab' role='tablist'>
            <li className='nav-item m-0' role='presentation'>
              <button
                className='nav-link active'
                id='lists-tab'
                data-bs-toggle='tab'
                data-bs-target='#lists'
                type='button'
                role='tab'
                aria-controls='lists'
                aria-selected='true'
              >
                Pending
              </button>
            </li>
            <li className='nav-item m-0' role='presentation'>
              <button
                className='nav-link'
                id='approved-tab'
                data-bs-toggle='tab'
                data-bs-target='#approved'
                type='button'
                role='tab'
                aria-controls='approved'
                aria-selected='true'
              >
                Approved
              </button>
            </li>
            <li className='nav-item m-0' role='presentation'>
              <button
                className='nav-link'
                id='rejected-tab'
                data-bs-toggle='tab'
                data-bs-target='#rejected'
                type='button'
                role='tab'
                aria-controls='rejected'
                aria-selected='true'
              >
                Rejected
              </button>
            </li>
          </ul>
          <div className='mx-2 store_search'>
            <button className='orange_btn' style={{marginRight:"2px"}} type='button' onClick={onNavigatetoAddPackage}>
              Submit new plugin
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className='tab-content'>
          <div className='tab-pane active' id='lists' role='tabpanel' aria-labelledby='lists-tab'>
            <div className='plugins_list row plugin_row'>
              {Array.isArray(userPlugin) && userPlugin.length > 0 ? (
                userPlugin.map((plugin: any) => {
                  return (

                    <UserPackagesCard item={plugin} />
                  );
                })
              ) : (
                <div className='col-12'>
                  <p>No plugins available</p>
                </div>
              )}
            </div>
          </div>
          <div className='tab-pane' id='approved' role='tabpanel' aria-labelledby='approved-tab'>
                <div className='text-white'>
                  nothing
                </div>
          </div>
          <div className='tab-pane' id='rejected' role='tabpanel' aria-labelledby='rejected-tab'>

          </div>
        </div>
      </div>
    </main>
  );
};

export default UserPackages;
