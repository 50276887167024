import { createSlice } from '@reduxjs/toolkit';

type Plugin = any;

type InitialState = {
  value: {
    plugins: Plugin[];
    allPlugins: Plugin[];
  };
};

const initialState: InitialState = {
  value: {
    plugins: [],
    allPlugins: [],
  },
};

export const userplugins = createSlice({
  name: 'userplugins',
  initialState,
  reducers: {
    setAllPlugins: (state, action) => {
      state.value.allPlugins = action.payload;
    },
    setUserPlugins: (state, action) => {
      state.value.plugins = action.payload;
    },
    addUserPlugin: (state, action) => {
      let obj = {
        ...action.payload,
      };
      state.value.plugins.push(obj);
    },
    removeUserPlugin: (state, action) => {
      const plugins = state.value.plugins;
      if (!plugins) return;

      // for (const i in plugins) {
      //   if (plugins[i].id === action.payload.id) {
      //     state.value.plugins.splice(i, 1);
      //   }
      // }
    },
    updatePluginMutation: (state, action) => {
      let plugins = state.value.plugins;
      const newplugins = plugins.map(plugin => {
        if (plugin.id === action.payload?.pluginId) {
          let index = plugin.projects.indexOf(action.payload?.projectId);
          if (index > -1) {
            plugin.projects.splice(index, 1);
          } else {
            plugin.projects.push(action.payload?.projectId);
          }
        }
        return plugin;
      });
      state.value.plugins = newplugins;
    },
  },
});

export const { setAllPlugins, setUserPlugins, addUserPlugin, removeUserPlugin, updatePluginMutation } =
  userplugins.actions;

export default userplugins.reducer;
