import React from 'react';
import Sidenav from './Sidenav';
import Header from './Header';
import { Outlet } from 'react-router-dom';

interface CommonLayoutProps {
  children?:any
}

const CommonLayout:React.FC<CommonLayoutProps> = ({ children }: any) => {
  return (
    <>
      <Sidenav />
      <Header />
      <Outlet />
    </>
  );
}

export default CommonLayout;
