import React, { useState } from 'react';
import { useForgotPassMutation } from '../features/api';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import fusepress_logo from '../img/fusepress_logo.png';

const ForgotPassword:React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [error, setError] = useState<any>({});

  const [Auth] = useForgotPassMutation();

  const validateForm = (): any => {
    const newError: any = {};
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (!formData.email) {
      newError.email = 'Email is required';
    } else if (!emailPattern.test(formData.email)) {
      newError.email = 'Email must be in lowercase and have a valid format';
    }

    setError(newError);

    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      setDisableButton(true)
      let data = {
        email: formData.email,
      };
      Auth(data)
        .then((res: any) => {
          setDisableButton(false)
          if (res.data.error === false) {
            // toast.success(`${res.data.message}`);
            toast.success(`${res.data.message}`, { theme: "colored", autoClose: 1000 });
          } else {
            // toast.error(`${res.data.message}`);
            toast.error(`${res.data.message}`, { theme: "colored", autoClose: 1000 });
          }
        })
        .catch((err: any) => {
          // toast.error(`${err}`);
          toast.error(`${err}`, { theme: "colored", autoClose: 1000 });
        });
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <main className='main'>
      <div className='main_auth_inner'>
        <div className='home_logo'>
          <img src={fusepress_logo} alt='fusepress_logo' />
        </div>
        <div className='auth'>
          <form onSubmit={handleSubmit}>
            <div className='input-container'>
              <FontAwesomeIcon icon={faEnvelope} className='fa fa-envelope icon' />
              <input
                type='email'
                className='input-field'
                placeholder='Email Id'
                name='email'
                onChange={handleChange}
                value={formData.email}
              />
              {error.email && <span style={{ color: 'red', fontSize: '14px' }}>{error.email}</span>}
            </div>
            {/* <button className='btn_submit' disabled={disableButton}>{disableButton ? 'Loading...' : 'Submit'}</button> */}
            <button
              className='btn_submit'
              disabled={disableButton}
              style={{ cursor: disableButton ? 'not-allowed' : 'pointer' }}
            >
              {disableButton ? 'Loading...' : 'Submit'}
            </button>
            <div className='return_to_home'>
              <Link to='/auth' className='fp_return_home'>
                Return to login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;
