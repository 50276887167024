import React, { useState, useEffect } from 'react';
import { useUpdatePassMutation } from '../features/api';
import { faLock, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fusepress_logo from '../img/fusepress_logo.png';
import { useNavigate } from 'react-router-dom';

const ResetPassword:React.FC = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: '',
  });
  const navigate = useNavigate();
  const [id, setId] = useState<any>({});

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    setId(id);
  }, []);

  const [newicon, setNewIcon] = useState(faEyeSlash);
  const [confirmicon, setConfirmIcon] = useState(faEyeSlash);
  const [newpasswordShow, setNewPasswordShow] = useState(false);
  const [confirmpasswordShow, setConfirmpasswordShow] = useState(false);
  const [resetPassError,setResetPassError] = useState<any>({})
  const [Auth] = useUpdatePassMutation();

  const toggleNewPassword = (e: any) => {
    setNewPasswordShow(!newpasswordShow);
    if (newpasswordShow) {
      setNewIcon(faEyeSlash);
    } else {
      setNewIcon(faEye);
    }
  };

  const toggleConfirmPassword = (e: any) => {
    setConfirmpasswordShow(!confirmpasswordShow);
    if (confirmpasswordShow) {
      setConfirmIcon(faEyeSlash);
    } else {
      setConfirmIcon(faEye);
    }
  };
  // const validatePassword = () => {
  //   let newError: any = {};
  //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  //   if (!formData.password) {
  //     newError.password = 'Password is required.';
  //   } else if (!passwordRegex.test(formData.password)) {
  //     newError.password = 'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long.';
  //   }
  //   if (!formData.confirmpassword) {
  //     newError.confirmpassword = 'Confirm password is required.';
  //   }
  //   else if (formData.password !== formData.confirmpassword) {
  //     newError.confirmpassword = 'New Password and Confirm Password must be same';
  //   }

  //   setResetPassError(newError);
  //   return Object.keys(newError).length === 0;
  // };

  const validatePassword = () => {
    let newError: any = {};

    const containsRequiredCharsForPassword = (str: string) => {
      return str &&
        str.length >= 8 &&
        /[A-Za-z]/.test(str) &&
        /\d/.test(str) &&
        /[@$!%*?&^#]/.test(str);
    };
    const password = formData.password.trim();
    console.log("Password being tested:", password);

    if (!password) {
      newError.password = 'Password is required.';
    } else if (!containsRequiredCharsForPassword(password)) {
      newError.password = 'Password must be at least 8 characters long, contain at least one letter, one digit, and one special character (@, $, !, %, *, ?, &, ^, #).';
    }
    const confirmPassword = formData.confirmpassword.trim();
    if (!confirmPassword) {
      newError.confirmpassword = 'Confirm password is required.';
    } else if (password !== confirmPassword) {
      newError.confirmpassword = 'Password and Confirm Password must be the same.';
    }
    setResetPassError(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if(validatePassword()){
      let data = {
        password: formData.password,
        confirmpassword: formData.confirmpassword,
        id: id,
      };
      Auth(data)
        .then((res: any) => {
          if (Object.keys(res).length > 0) {
            if (res.data.hasOwnProperty('error')) {
              toast.error(`${res.data.message}`);
            } else {
              toast.success(`${res.data.message}`);
              navigate('/auth');
            }
          }
        })
        .catch((err: any) => {
          toast.error(`${err}`);
        });
    }

  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <main className='main'>
      <div className='main_auth_inner'>
        <div className='home_logo'>
          <img src={fusepress_logo} alt='fusepress_logo' />
        </div>
        <div className='auth'>
          <form onSubmit={handleSubmit}>
            <div className='input-container'>
              <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
              <input
                type={newpasswordShow ? 'text' : 'password'}
                className='input-field'
                placeholder='New Password'
                name='password'
                onChange={handleChange}
                value={formData.password}
              />
              <FontAwesomeIcon icon={newicon} className='icon icon_sec' onClick={toggleNewPassword} />
            </div>
            {resetPassError.password && (
              <div style={{ color: 'red', fontSize: '14px' }}>{resetPassError.password}</div>
            )}
            <div className='input-container'>
              <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
              <input
                type={confirmpasswordShow ? 'text' : 'password'}
                className='input-field'
                placeholder='Confirm Password'
                name='confirmpassword'
                onChange={handleChange}
                value={formData.confirmpassword}
              />
              <FontAwesomeIcon icon={confirmicon} className='icon icon_sec' onClick={toggleConfirmPassword} />
            </div>
            {resetPassError.confirmpassword && (
              <div style={{ color: 'red', fontSize: '14px' }}>{resetPassError.confirmpassword}</div>
            )}
            <button className='btn_submit'>Reset Password</button>
          </form>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
