import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  userDetails: [],
  data:null,
  status: null,
  isLoading: false,
  isError: false,
  errorMessage: null,
};

export const fetchUserDetails:any = createAsyncThunk('userDetails/fetchUserDetails', async (_,{rejectWithValue}) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/getUserDetails`,
      method: 'POST',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error:any) {
    // const state = getState();
    console.error('Error fetching user details:', error);
    // console.error('Current state:', state);
    return rejectWithValue(error.response ? error.response.data : error.message)
  }
});

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState:initialState,
  reducers: {},
  extraReducers: {
    [fetchUserDetails.pending]: (state: any, action: any) => {
      state.status = 'pending';
      state.isLoading = true;
      state.isError = false;  // Reset error state when starting a new request
      state.errorMessage = null;  // Clear previous error message
    },
    [fetchUserDetails.fulfilled]: (state: any, action: any) => {
      state.status = 'success';
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    [fetchUserDetails.rejected]: (state: any, action: any) => {
      state.status = 'rejected';
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

// export const selectUserDetailsState = (state:any) => state.userDetails;

export default userDetailsSlice.reducer;
