import { createSlice } from '@reduxjs/toolkit';
import { createSearchParams } from 'react-router-dom';

function setUrlSearch1(filters: any) {
  // console.log("filters",filters)
  const params =
    '?' +
    createSearchParams({
      search: filters.search,
      tag: filters.tag,
    });
  window.history.pushState(params, '', params);
}

function getState() {
  const search = new URLSearchParams(decodeURIComponent(window.location.search));
  return {
    value: {
      tag:search.get('tag') ?? '',
      search: search.get('search') ?? '',
    },
  };
}

export const featuredFiltersSlice = createSlice({
  name: 'featuredFilters',
  initialState: getState(),
  reducers: {
    calculate: state => {
      state.value = getState().value;
    },
    setTag: (state, action) => {
      state.value.tag = action.payload;
      setUrlSearch1(state.value);
    },
    setSearch: (state, action) => {
      state.value.search = action.payload;
      setUrlSearch1(state.value);
    },
    clearSearch: (state) => {
      state.value.search = '';
    },
  },
});

export const {
  calculate,
  setTag,
  setSearch,
  clearSearch,
} = featuredFiltersSlice.actions;

export default featuredFiltersSlice.reducer;
