import { createSlice } from '@reduxjs/toolkit';

export const billing = createSlice({
  name: 'billing',
  initialState: {
    value: {
      open: null,
      all: {},
    },
  },
  reducers: {
    setPlugins: (state: any, action) => {
      let i = 1;
      for (const plugin of action.payload) {
        state.value.all[i] = plugin;
        i++;
      }
    },
    togglePlugin: (state, action) => {
      if (state.value.open === action.payload) {
        state.value.open = null;
      } else {
        state.value.open = action.payload;
      }
    },
    openPlugin: (state, action) => {
      state.value.open = action.payload;
    },
  },
});

export const { setPlugins, togglePlugin, openPlugin } = billing.actions;

export default billing.reducer;
