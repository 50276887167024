/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../features/api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

interface RegisterInterface{
  setToggle:React.Dispatch<SetStateAction<boolean>>
}

const Register:React.FC<RegisterInterface> = ({setToggle}) => {
  const navigate = useNavigate();
  const [icon, setIcon] = useState(faEyeSlash);
  const [icon1, setIcon1] = useState(faEyeSlash);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState<any>({});
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [AuthRegister] = useRegisterMutation();

  const togglePassword = () => {
    setPasswordShow(!passwordShow);
    if (passwordShow) {
      setIcon(faEyeSlash);
    } else {
      setIcon(faEye);
    }
  };

  const toggleconfirmPassword = () => {
    setConfirmPasswordShow(!confirmpasswordShow);
    if (confirmpasswordShow) {
      setIcon1(faEyeSlash);
    } else {
      setIcon1(faEye);
    }
  };

  const validateForm = () => {
    let newError: any = {};
    const validNamePattern = /^[^\s]+(\s[^\s]+)*$/;
    const alphabeticPattern = /^[a-zA-Z\s]+$/;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (!formData.fname) {
      newError.fname = 'First name is required';
    } else if (!validNamePattern.test(formData.fname)) {
      newError.fname = 'First name cannot contain  spaces';
    } else if (!alphabeticPattern.test(formData.fname)) {
      newError.fname = 'First name can only contain alphabetic characters';
    }
    if (!formData.lname) {
      newError.lname = 'Last name is required';
    } else if (!validNamePattern.test(formData.lname)) {
      newError.lname = 'Last name cannot contain  spaces';
    } else if (!alphabeticPattern.test(formData.lname)) {
      newError.lname = 'Last name can only contain alphabetic characters';
    }
    if (!formData.email) {
      newError.email = 'Email is required';
    } else if (!emailPattern.test(formData.email)) {
      newError.email = 'Email must be in lowercase and have a valid format';
    }
    if (!formData.password) {
      newError.password = 'Password is required';
    } else if (/\s/.test(formData.password)) {
      newError.password = 'Password cannot contain spaces';
    }
    if (!formData.confirm_password) {
      newError.confirm_password = 'Confirm password is required';
    } else if (/\s/.test(formData.confirm_password)) {
      newError.confirm_password = 'Confirm password cannot contain spaces';
    } else if (formData.confirm_password !== formData.password) {
      newError.confirm_password = 'Password and confirm password do not match';
    }

    const checkBox = document.getElementById('tc') as HTMLInputElement;
    if (!checkBox.checked) {
      newError.checkbox = 'Please agree to the terms and conditions.';
    }
    setError(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      setDisableButton(true)
      let data = {
        fname: formData.fname,
        lname: formData.lname,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirm_password,
      };
      AuthRegister(data)
        .then((res: any) => {
          if (Object.keys(res).length > 0) {
            setDisableButton(false)
            if (res.data.hasOwnProperty('error')) {
              // toast.error(`${res.data.message}`);
              toast.error(`${res.data.message}`, { theme: "colored", autoClose: 1000 });
            } else {
              const email = formData.email;
              const atIndex = email.indexOf('@');
              if (atIndex !== -1) {
                const emailBeforeAt = email.substring(0, atIndex);
                axios({
                  url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
                  method: 'POST',
                  headers: {},
                  params: {
                    action: 'get_user_data',
                    fname: formData.fname,
                    lname: formData.lname,
                    username: emailBeforeAt,
                    password: btoa(formData.password),
                    email: formData.email,
                    role: 'customer',
                  },
                  data: {},
                })
                  .then(res1 => {
                    setFormData({
                      fname: '',
                      lname: '',
                      email: '',
                      password: '',
                      confirm_password: '',
                    });
                    // toast.info(res1.data);
                    toast.success('User registered successfully!' , { theme: "colored", autoClose: 1000 });
                    // toast.info(res1.data, { theme: "colored", autoClose: 1000 });
                    setToggle(true)
                  })
                  .catch(err1 => {
                    setFormData({
                      fname: '',
                      lname: '',
                      email: '',
                      password: '',
                      confirm_password: '',
                    });
                  });
              }
              // axios({
              //   url:`${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
              //   method:"GET",
              //   headers:{},
              //   params:{
              //     action:"get_user_data",
              //     fname: formData.fname,
              //     lname: formData.lname,
              //     username: `${formData.fname} ${formData.lname}`,
              //     password: btoa(formData.password),
              //     email: formData.email,
              //     role: "customer",
              //   },
              //   data:{}
              // }).then((res1) => {
              //   console.log("res1",res1.data);
              // }).catch((err1) => {
              //   console.log("err",err1)
              // })
              // const baseUrl = `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php?action=get_user_data`;
              // const queryParams = new URLSearchParams({
              //   fname: formData.fname,
              //   lname: formData.lname,
              //   username: `${formData.fname} ${formData.lname}`,
              //   password: btoa(formData.password),
              //   email: formData.email,
              //   role: "customer",
              // });

              // const newUrl = `${baseUrl}&?${queryParams.toString()}`;
              // window.location.href = newUrl;
              // setTimeout(() => {
              //   window.location.href = '/auth';
              // }, 5000);
            }
          }
        })
        .catch((err: any) => {
          // toast.error(`${err}`);
          toast.error(`${err}`, { theme: "colored", autoClose: 1000 });
        });
      console.log('form submited');
    } else {
      console.log('Form validation failed');
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className='fp_custom_registration'>
      <form onSubmit={handleSubmit}>
        <div className='rowc'>
          <div className='col-md-6c'>
            <div className='input-container'>
              <FontAwesomeIcon icon={faUser} className='fa fa-user icon' />
              <input
                type='text'
                className='input-field'
                placeholder='First Name'
                name='fname'
                onChange={handleChange}
                value={formData.fname}
              />
              {error.fname && <span style={{ color: 'red', fontSize: '14px' }}>{error.fname}</span>}
            </div>
          </div>
          <div className='col-md-6c'>
            <div className='input-container'>
              <FontAwesomeIcon icon={faUser} className='fa fa-user icon' />
              <input
                type='text'
                className='input-field'
                placeholder='Last Name'
                name='lname'
                onChange={handleChange}
                value={formData.lname}
              />
              {error.lname && <span style={{ color: 'red', fontSize: '14px' }}>{error.lname}</span>}
            </div>
          </div>
        </div>
        <div className='input-container'>
          <FontAwesomeIcon icon={faEnvelope} className='fa fa-envelope icon' />
          <input
            type='email'
            className='input-field'
            placeholder='Email Id'
            name='email'
            onChange={handleChange}
            value={formData.email}
          />
          {error.email && <span style={{ color: 'red', fontSize: '14px' }}>{error.email}</span>}
        </div>
        <div className='input-container'>
          <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
          <input
            type={passwordShow ? 'text' : 'password'}
            className='input-field'
            placeholder='Password'
            name='password'
            onChange={handleChange}
            value={formData.password}
          />
          <FontAwesomeIcon icon={icon} className='icon icon_sec' onClick={togglePassword} />
          {error.password && <span style={{ color: 'red', fontSize: '14px' }}>{error.password}</span>}
        </div>
        <div className='input-container'>
          <FontAwesomeIcon icon={faLock} className='fa fa-lock icon' />
          <input
            type={confirmpasswordShow ? 'text' : 'password'}
            className='input-field'
            placeholder='Confirm Password'
            name='confirm_password'
            onChange={handleChange}
            value={formData.confirm_password}
          />
          <FontAwesomeIcon icon={icon1} className='icon icon_sec' onClick={toggleconfirmPassword} />
          {error.confirm_password && <span style={{ color: 'red', fontSize: '14px' }}>{error.confirm_password}</span>}
        </div>
        <div className='input-container'>
          <input type='checkbox' id='tc' name='tc' />
          <label className='tc'>
            I agree to the website <a href='https://fusepress.co/terms-of-service/?source=external' onClick={() => localStorage.setItem('source', 'external')}>terms and conditions.</a>
          </label>
          {error.checkbox && <span style={{ color: 'red', fontSize: '14px' }}>{error.checkbox}</span>}
        </div>
        <button
          className='btn_submit'
          disabled={disableButton}
          style={{ cursor: disableButton ? 'not-allowed' : 'pointer' }}
        >
          {disableButton ? 'Loading...' : 'Register'}
        </button>

        <p className='log_reg_with'>Or Register with</p>
      </form>
    </div>
  );
}

export default Register;
